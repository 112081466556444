import React from "react";
import Blog from "./blog";
import INFO from "../../data/user";

import "./styles/allBlogs.css";

const AllBlogs = () => {
	return (
		<div className="all-blogs-container">
			{INFO.blogs.map((blog, index) => (
				<div className="all-blogs-blog" key={index}>
					<Blog
						logo={blog.logo}
						title={blog.title}
						description={blog.description}
						linkText={blog.linkText}
						link={blog.link}
					/>
				</div>
			))}
		</div>
	);
};

export default AllBlogs;
