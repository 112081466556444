function education_1() {
    return {
        date: "Aug 2021 - Dec 2022",
        title: "University at Buffalo, The State University of New York",
        logo: "ub",
        description: "Masters Degree in Computer Science and Engineering",
        courses: "Intro to Machine Learning, Algorithm Analysis and Design, Intro to Computer Vision & Image Processing, Deep Learning, Information retrieval, Distributes Systems, NLP for Social Good, Neorsymbolic-AI"
    };
}

function education_2() {
    return {
        date: "Aug 2017 - June 2021",
        title: "University of Mumbai",
        logo: "mu",
        description: "Bachelors Degree in Computer Engineering",
        courses: "Data Minning & Business intelligence, Artificial Intelligence, Soft Computing, Big Data Analytics, DevOps, Data Structures & Analysis, Database Management Systems, Operating Systems, Internet of Things."
    };
}

const myEducation = [education_1, education_2];

export default myEducation;