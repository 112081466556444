import "./uspdp.css";

const USPDP = (props) => {
	return (
		<html>
			<head>
				<meta
					http-equiv="Content-Type"
					content="text/html; charset=utf-8"
				/>
				<title>
					📦 Unified System for Package Damage Prediction using AI
				</title>
			</head>
			<body>
				<article
					id="710f8b98-2de4-4b29-85d1-04179ae45734"
					className="page sans"
				>
					<header>
						<div className="page-header-icon undefined">
							<span className="icon">🦺</span>
						</div>
						<h1 className="page-title">
							<strong>
								📦 Unified System for Package Damage Prediction
								using AI
							</strong>
						</h1>
						<p className="page-description"></p>
					</header>
					<div className="page-body">
						<h3
							id="06c779c2-7604-4873-bb23-af982108995c"
							className=""
						>
							👨🏻‍💻 <strong>Scope</strong>
						</h3>
						<ul
							id="c1904088-ae52-469d-9b74-9e623b9bb52d"
							className="bulleted-list"
						>
							<li>
								<strong>Addressing High Volume Damage</strong>:
								Tackling the issue of approximately 600,000
								damaged packages out of 70 million delivered
								annually in the US by UPS.
							</li>
						</ul>
						<ul
							id="85ec285a-681a-4ac8-b281-c21a41ec6bdc"
							className="bulleted-list"
						>
							<li>
								<strong>
									Customer Experience and Operational Loss
								</strong>
								: Focused on enhancing customer satisfaction and
								reducing losses due to package damages.
							</li>
						</ul>
						<ul
							id="5b3e5e1a-065c-48b3-abcf-2bf66261a681"
							className="bulleted-list"
						>
							<li>
								<strong>
									Innovative Machine Learning Solution
								</strong>
								: Implementing smart predictive analysis to
								forecast potential package damages.
							</li>
						</ul>
						<ul
							id="c4eecfc9-596d-4bff-84ab-a96ec1c8fa32"
							className="bulleted-list"
						>
							<li>
								<strong>Comprehensive Monitoring System</strong>
								: Developing a dashboard for analytics and
								monitoring to improve hub performance and
								operational planning.
							</li>
						</ul>
						<ul
							id="135400f4-8a29-491d-bb0a-752596f2b2aa"
							className="bulleted-list"
						>
							<li>
								<strong>Proactive Alert System</strong>:
								Providing notifications for expected spikes in
								damages based on historical trends.
							</li>
						</ul>
						<blockquote
							id="976733d0-056d-45c7-89df-22591dc721e5"
							className="block-color-teal"
						>
							<em>
								Confronting the critical issue of damaged
								packages to optimize customer satisfaction and
								operational efficiency in UPS deliveries.
							</em>
						</blockquote>
						<h3
							id="d09fac23-4800-4474-a5ab-f518a0bbdc3e"
							className=""
						>
							<strong>📊 Data Collection</strong>
						</h3>
						<ul
							id="7478ab6f-d940-4922-97c4-f1ba74a84f04"
							className="bulleted-list"
						>
							<li>
								<strong>Extensive Delivery Data</strong>:
								Analyzed data from 70 million UPS package
								deliveries over several years.
							</li>
						</ul>
						<ul
							id="c216adfd-1757-4608-bac7-cb695b613678"
							className="bulleted-list"
						>
							<li>
								<strong>In-Depth Tracking</strong>: Collected
								package tracking numbers, delivery data, and
								specific details on reported damages.
							</li>
						</ul>
						<ul
							id="70ae2d4e-2cbc-40e7-8142-4fa1777e326f"
							className="bulleted-list"
						>
							<li>
								<strong>ULD Movement Analysis</strong>: Examined
								UPS Container (ULD) information, including
								arrival and departure details at each hub.
							</li>
						</ul>
						<ul
							id="ec797137-a41d-4f66-90d6-e250220cbf85"
							className="bulleted-list"
						>
							<li>
								<strong>Load Quality Audit Data</strong>:
								Incorporated load quality audit checklists and
								scores, alongside load percentages, for detailed
								insight.
							</li>
						</ul>
						<ul
							id="43404ce4-1b3d-4708-8b27-e949fd93c4a5"
							className="bulleted-list"
						>
							<li>
								<strong>Comprehensive Coverage</strong>: Data
								encompassed 20 districts, 650 centers, and
								considered various factors across 200 million
								packages.
							</li>
						</ul>
						<ul
							id="669d52eb-cc6e-41c8-8616-a9f9e4a74e8a"
							className="bulleted-list"
						>
							<li>
								<strong>Algorithmic Diversity</strong>: Utilized
								15 different algorithms for in-depth analysis
								and predictive modeling.
							</li>
						</ul>
						<blockquote
							id="12e29ca9-6651-416b-a9a9-979d2080e98c"
							className="block-color-blue"
						>
							<em>
								Extensive data collection serves as a foundation
								for predictive analytics and damage reduction
								strategies.
							</em>
						</blockquote>
						<h3
							id="7a77f260-749e-4c16-bcd5-7a9f95930d07"
							className=""
						>
							<strong>📈 EDA (Exploratory Data Analysis)</strong>
						</h3>
						<ul
							id="faa767d6-2479-4c2c-ba98-347a2125a5db"
							className="bulleted-list"
						>
							<li>
								<strong>Handling Missing Data</strong>:
								Identified two months of missing daily data due
								to system issues, supplemented with weekly
								aggregate data from each hub.
							</li>
						</ul>
						<ul
							id="cfd89a11-c183-4fa2-94a9-c9395c48c066"
							className="bulleted-list"
						>
							<li>
								<strong>Data Imputation Strategy</strong>:
								Utilized previous year&#x27;s daily percent
								changes, ongoing trends, and seasonality factors
								to fill in gaps.
							</li>
						</ul>
						<ul
							id="59c11950-9440-40f1-91e5-50643ef87223"
							className="bulleted-list"
						>
							<li>
								<strong>Data Profiling and Cleaning</strong>:
								Undertook thorough data profiling, cleaning, and
								minor imputations to refine the dataset.
							</li>
						</ul>
						<ul
							id="a0b04596-7d65-487f-be8c-a8eb04a7b24b"
							className="bulleted-list"
						>
							<li>
								<strong>Time Series Analysis</strong>: Conducted
								hypothesis testing to examine seasonality and
								trends in the time series data.
							</li>
						</ul>
						<ul
							id="3a5353c6-117e-480b-a910-5ce20d8e4877"
							className="bulleted-list"
						>
							<li>
								<strong>Stationarity Check</strong>: Applied
								Dickey Fuller test to assess data stationarity,
								ensuring its suitability for predictive
								analysis.
							</li>
						</ul>
						<blockquote
							id="42aa10e0-3364-41da-9e6c-c9b90bfcb37f"
							className="block-color-brown"
						>
							<em>
								In-depth EDA to reveal key patterns and prepare
								data for precise forecasting.
							</em>
						</blockquote>
						<h3
							id="fdebed2d-f7eb-4b35-9c11-1662b33e37ef"
							className=""
						>
							<strong>🧠 Model Development</strong>
						</h3>
						<ul
							id="c4bff19e-cb79-4e2a-bc50-faf67a9713bc"
							className="bulleted-list"
						>
							<li>
								<strong>Diverse Algorithm Testing</strong>:
								Evaluated multiple algorithms including Random
								Forest Regressor (1.41% accuracy), Gradient
								Boosting Regressor (3.19%), and Ada Boost
								Regressor (5.07%).
							</li>
						</ul>
						<ul
							id="626a361e-16f3-4346-8749-1a4339dca1d6"
							className="bulleted-list"
						>
							<li>
								<strong>Advanced Forecasting Techniques</strong>
								: Incorporated LSTM (85.87% accuracy), GRU
								(82.66%), and ARIMA (78.24%) for sophisticated
								forecasting.
							</li>
						</ul>
						<ul
							id="5aadb1ad-83fd-4a2a-9536-58877a2b85f9"
							className="bulleted-list"
						>
							<li>
								<strong>
									Regression with Time-Dependent Features
								</strong>
								: Explored regression models by incorporating
								time-dependent features.
							</li>
						</ul>
						<ul
							id="28c22286-ba67-4b4b-929a-4e133fbc7075"
							className="bulleted-list"
						>
							<li>
								<strong>Final Model - Prophet</strong>: Achieved
								an average test accuracy of 87% using Prophet,
								with a model tailored for each Facility/Hub.
							</li>
						</ul>
						<ul
							id="3f685ae1-f8db-4041-9e28-ef4c80d55c12"
							className="bulleted-list"
						>
							<li>
								<strong>Anomaly Detection System</strong>:
								Implemented various methods like FB Prophet and
								heuristic rules, finally using a bagging
								approach for anomaly identification.
							</li>
						</ul>
						<ul
							id="9b1b7129-0595-464c-aa14-7e6534b6c80b"
							className="bulleted-list"
						>
							<li>
								<strong>Optimizing Anomaly Detection</strong>:
								Applied threshold moving techniques to balance
								true positives and false negatives, with XGBoost
								performing best.
							</li>
						</ul>
						<ul
							id="85de7680-2f42-4fad-9205-0fb355105ccb"
							className="bulleted-list"
						>
							<li>
								<strong>Probabilistic Forecasts</strong>:
								Instead of definitive anomaly predictions,
								provided probabilistic forecasts for informed
								decision-making.
							</li>
						</ul>
						<blockquote
							id="ad61c2f5-34ec-4587-acec-d564f03210eb"
							className="block-color-pink"
						>
							<em>
								Employing a range of sophisticated algorithms,
								from tree-based regressors to advanced neural
								networks, ensuring optimal predictive
								performance.
							</em>
						</blockquote>
						<h3
							id="6070b223-799e-4ae0-9787-2fcdaebaaeb5"
							className=""
						>
							<strong>🚀 Testing and Deployment</strong>
						</h3>
						<h3
							id="f8a1eafc-a4a5-4097-9cdf-10cc2dac34da"
							className=""
						>
							<strong>Testing and Evaluation</strong>
						</h3>
						<ul
							id="a9d984e1-4d48-4dff-b588-35f97efb4b50"
							className="bulleted-list"
						>
							<li>
								<strong>Ongoing Initial Testing</strong>: The
								first version of the model is currently under
								testing.
							</li>
						</ul>
						<ul
							id="90a0caf8-d53d-4efe-8cad-75cd429c9af9"
							className="bulleted-list"
						>
							<li>
								<strong>A/B Testing Strategy</strong>: Dividing
								hubs into experimental and control groups to
								assess the impact of forecast and anomaly
								probability notifications.
							</li>
						</ul>
						<ul
							id="7496cb9f-af43-4132-a254-453d7a2aecaf"
							className="bulleted-list"
						>
							<li>
								<strong>Performance Metrics Observation</strong>
								: Monitoring a matrix to evaluate model
								performance with real-time data.
							</li>
						</ul>
						<ul
							id="fab56940-3b0c-43b3-abc2-d688eec9a9f5"
							className="bulleted-list"
						>
							<li>
								<strong>
									Hypothesis Testing for Generalization
								</strong>
								: Conducting tests to ensure the solution&#x27;s
								applicability beyond the testing phase.
							</li>
						</ul>
						<h3
							id="8775ed24-1316-4e3d-a30b-1ae247591292"
							className=""
						>
							<strong>Deployment</strong>
						</h3>
						<ul
							id="67048f02-80d3-4c5d-9137-e4bd2977b3de"
							className="bulleted-list"
						>
							<li>
								<strong>Cloud Deployment</strong>: Launched the
								solution on GCP Cloud Run, ensuring scalability
								and reliability.
							</li>
						</ul>
						<ul
							id="fb63a9d0-5231-411c-9fbf-14a3db32ee70"
							className="bulleted-list"
						>
							<li>
								<strong>Load Balancing</strong>: Utilized load
								balancers for efficient handling of external
								requests.
							</li>
						</ul>
						<ul
							id="6688fdc8-2dac-4a72-9b31-4d22e06c41fe"
							className="bulleted-list"
						>
							<li>
								<strong>Data Storage</strong>: Employed BigQuery
								(BQ) and Google Cloud Storage (GCS) for robust
								data management.
							</li>
						</ul>
						<blockquote
							id="51b7b26c-aa5d-487d-a341-6c75cfc38e5a"
							className="block-color-teal"
						>
							Comprehensive testing and cloud-based deployment
							ensure the solution&#x27;s effectiveness and
							scalability.
						</blockquote>
						<p
							id="617ea4ea-9424-4d0c-9d54-61250954f93e"
							className=""
						></p>
					</div>
				</article>
				<span className="sans"></span>
			</body>
		</html>
	);
};

export default USPDP;
