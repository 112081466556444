import React from "react";
import INFO from "../../data/user";
import Chip from "@mui/material/Chip";
import "./styles/experience.css";
import Stack from "@mui/material/Stack";

const Experience = (props) => {
	const { date, title, description, logo, skills, width, height } =
		props;

	return (
		<React.Fragment>
			<div className="experience">
				<div className="experience-left-side">
					<img
						src={INFO.experience[logo]}
						alt="logo"
						className="experience-logo"
						style={{ width: width, height: height }}
					/>
					<div className="experience-date">{date}</div>
				</div>

				<div className="experience-right-side">
					<div className="experience-title">{title}</div>
					<div
						className="experience-description"
						dangerouslySetInnerHTML={{ __html: description }}
					></div>
					<Stack direction="row" spacing={1}>
						{skills &&
							skills.map((skill, index) => {
								return (
									<Chip
										key={index}
										label={skill}
										variant="outlined"
									/>
								);
							})}
					</Stack>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Experience;