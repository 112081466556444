import "./safeq.css";

const Safeq = (props) => {
	return (
		<html>
			<head>
				<meta
					http-equiv="Content-Type"
					content="text/html; charset=utf-8"
				/>
				<title>🦠 Covid-19 SafeQ</title>
			</head>
			<body>
				<article
					id="66e507f1-93d6-4dbc-beef-949af7a6ce96"
					className="page sans"
				>
					<header>
						<div className="page-header-icon undefined">
							<span className="icon">🛒</span>
						</div>
						<h1 className="page-title">
							<strong>
								<strong>🦠 Covid-19 SafeQ</strong>
							</strong>
						</h1>
						<p className="page-description"></p>
					</header>
					<div className="page-body">
						<h3
							id="f89dc93c-c844-4504-9327-41619a276270"
							className=""
						>
							🛍️ Scope
						</h3>
						<ul
							id="78d3d355-b485-49d5-b6ab-6d626da9b76a"
							className="bulleted-list"
						>
							<li>
								Covid-19 pandemic has forced everybody to stay
								home all day and leave houses only for very
								essential commodities.
							</li>
						</ul>
						<ul
							id="db993c9e-f985-48ad-a2bf-06d89c7643a9"
							className="bulleted-list"
						>
							<li>
								All the grocery shops and vegetable markets that
								are essential commodities are only allowed to
								remain open for two hours in the morning.
							</li>
						</ul>
						<ul
							id="f8642cfe-ba35-4f8c-a842-1a468f7ffc5a"
							className="bulleted-list"
						>
							<li>
								Which leads to people standing in huge queues
								violating social distancing norms which is very
								important for completely eliminating this
								pandemic situation.
							</li>
						</ul>
						<ul
							id="5f497aaa-ff1d-48c9-bd87-8cded523ec38"
							className="bulleted-list"
						>
							<li>
								Preventing people to stand in such queues and
								stopping them from violating the social
								distancing norms we have come up with a solution
								called the Covid19 SafeQ.
							</li>
						</ul>
						<h3
							id="b3cf3578-4290-4770-af57-000b8c71b9b3"
							className=""
						>
							<strong>
								<strong>🎯 </strong>
							</strong>
							Proposed Solution
						</h3>
						<ul
							id="b0a3e0b2-0376-4c73-addb-eceb25a63ec4"
							className="bulleted-list"
						>
							<li>
								For us to beat and come up out of this covid-19
								situation it is very important for us to follow
								all the rules and norms that have been set by
								the government, and maintaining social
								distancing is one of the most important norm.
							</li>
						</ul>
						<ul
							id="87e79a54-619b-44ce-93f7-b7abd441cc05"
							className="bulleted-list"
						>
							<li>
								We have developed an application by which people
								can virtually stand in queues of the shop they
								wish to visit to buy essential commodities.
							</li>
						</ul>
						<ul
							id="7f7bf7e9-1fdc-44f1-8dbd-e8470400cec5"
							className="bulleted-list"
						>
							<li>
								Once they select the shop they want to visit
								they will receive the time at which they have to
								visit the shop without waiting in the queue and
								maintaining social distancing.
							</li>
						</ul>
						<h3
							id="ea654d1d-2d92-401f-88ec-0734b3179f92"
							className=""
						>
							<strong>
								<strong>📱 Features and Functionality</strong>
							</strong>
						</h3>
						<ul
							id="6060137e-c081-45ab-968f-8325da3747f0"
							className="bulleted-list"
						>
							<li>
								Covid19 SafeQ is a mobile application that
								allows users to virtually queue for shops.
							</li>
						</ul>
						<ul
							id="d01ac7e4-56eb-42e1-970b-f6a70ea8fb7e"
							className="bulleted-list"
						>
							<li>
								Users can view a map displaying essential shops
								within their locality.
							</li>
						</ul>
						<ul
							id="5677f169-85a0-4fd9-9369-9487dd906469"
							className="bulleted-list"
						>
							<li>
								Upon selecting a shop, users receive a virtual
								ticket with a designated shopping time,
								eliminating physical queues.
							</li>
						</ul>
						<ul
							id="9b991592-8a9a-4b3f-98b6-8767c10bc747"
							className="bulleted-list"
						>
							<li>
								Post-visit, waiting times for other customers in
								the virtual queue are automatically reduced.
							</li>
						</ul>
						<ul
							id="a58e1d8f-c42e-4eda-8fd1-2fcee84f5231"
							className="bulleted-list"
						>
							<li>
								Covid19 SafeQ can be integrated with the
								government-developed Arogya Setu app, alerting
								customers and shop owners about potential
								Covid-19 positive patients.
							</li>
						</ul>
						<ul
							id="7f8ff31f-cc53-45a3-9185-ccf69013f26d"
							className="bulleted-list"
						>
							<li>
								Users receive a ticket with waiting times based
								on the &#x27;basket size&#x27; selected by
								preceding
								customers.                                                                                                                                        
							</li>
						</ul>
						<h3
							id="33581683-da76-4634-9a2d-086edecf188d"
							className=""
						>
							<strong>🚀 Technology Used and Deployment</strong>
						</h3>
						<ul
							id="30f60bb0-1916-44ee-8ee4-bfd1958533ee"
							className="bulleted-list"
						>
							<li>
								<strong>Cloud Services</strong>: Leveraged cloud
								platforms for robust app hosting and data
								management.
							</li>
						</ul>
						<ul
							id="9253c256-c01b-473a-a72f-e13daf52169b"
							className="bulleted-list"
						>
							<li>
								<strong>Real-Time Queue Management</strong>:
								Implemented advanced algorithms for dynamic
								queuing and scheduling.
							</li>
						</ul>
						<ul
							id="95c91975-7714-4935-9578-084cfc8dc413"
							className="bulleted-list"
						>
							<li>
								<strong>User Interface</strong>: Developed a
								user-friendly app interface for easy navigation
								and accessibility.
							</li>
						</ul>
						<h3
							id="d6f0deba-1d82-474f-a230-643af95f3791"
							className=""
						>
							<strong>💚 Sustainability</strong>
						</h3>
						<ul
							id="e213276f-bd58-4ad7-9cd0-86fdfe8108cf"
							className="bulleted-list"
						>
							<li>
								<strong>Eco-Friendly Operations</strong>:
								Minimized physical gathering, contributing to
								reduced environmental impact.
							</li>
						</ul>
						<ul
							id="0112c5f5-9939-4e93-a271-2f2fa3f23873"
							className="bulleted-list"
						>
							<li>
								<strong>Community Health Impact</strong>:
								Significantly aids in maintaining social
								distancing, promoting public health.
							</li>
						</ul>
						<blockquote
							id="600af832-c058-4e94-a14f-1037b569a28e"
							className="block-color-teal"
						>
							SafeQ combines modern technology with a sustainable
							approach to address public health needs.
						</blockquote>
						<p
							id="d1328446-e2de-4fd6-878e-d14add655b20"
							className=""
						></p>
					</div>
				</article>
				<span className="sans"></span>
			</body>
		</html>
	);
};

export default Safeq;
