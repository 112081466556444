import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

import "./style/project.css";

const Project = (props) => {
	const { image, title, tags, description, link } = props;

	return (
		<React.Fragment>
			<div className="project">
				<div className="project-left-side">
					<img src={image} alt="about" className="about-image" />
				</div>

				<Link to={link}>
					<div className="project-right-side">
						<div className="project-title">{title}</div>
						<div className="project-description">{description}</div>
						<Stack
							direction="row"
							spacing={1}
							style={{ marginTop: "10px" }}
						>
							{tags.map((tag, index) => (
								<Chip
									label={tag}
									size="small"
									variant="outlined"
									key={index}
								/>
							))}
						</Stack>

						<div className="project-link">
							Read More{" "}
							<FontAwesomeIcon
								style={{ fontSize: "10px" }}
								icon={faChevronRight}
							/>
						</div>
					</div>
				</Link>
			</div>
		</React.Fragment>
	);
};

export default Project;
