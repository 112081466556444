import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import Project from "../components/projects/project";

import INFO from "../data/user";
import SEO from "../data/seo";
import myProjects from "../data/projects";

import "./styles/projects.css";

const Projects = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "projects");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Projects | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="projects" />
				<div className="content-wrapper">
					<div className="projects-logo-container">
						<div className="projects-logo">
							<Logo width={46} />
						</div>
					</div>

					<div className="projects-main-container">
						<div className="projects-main">
							<div className="projects-right-side">
								<div className="title projects-title">
									{INFO.projects.title}
								</div>

								<div className="subtitle projects-subtitle">
									{INFO.projects.description}
								</div>
							</div>
							<div className="projects-left-side">
								<div className="projects-image-container">
									<div className="projects-image-wrapper">
										<img
											src="projects.svg"
											alt="about"
											className="projects-image"
										/>
									</div>
								</div>

							</div>
						</div>

						<div className="projects-container">
							<div className="projects-wrapper">
								{myProjects.map((project, index) => (
									<div
										className="projects-project"
										key={(index + 1).toString()}
									>
										<Project
											key={(index + 1).toString()}
											image={project().image}
											title={project().title}
											tags={project().tags}
											description={project().description}
											link={"/project/" + (index + 1)}
										/>
									</div>
								))}
							</div>
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Projects;
