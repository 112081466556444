import React, { useEffect } from "react";

import "./styles/experience.css";
import SEO from "../data/seo";
import INFO from "../data/user";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";

import Experience from "../components/experience/experience";
import myExperience from "../data/experience";

const WorkExperience = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "experience");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Work Experience | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="experience" />
				<div className="content-wrapper">
					<div className="experiences-logo-container">
						<div className="experiences-logo">
							<Logo width={46} />
						</div>
					</div>
					<div className="experiences-container">
						<div className="experiences-main">
							<div className="experiences-right-side">
								<div className="title experiences-title">
									{INFO.experience.title}
								</div>

								<div
									className="subtitle experiences-subtitle"
									dangerouslySetInnerHTML={{
										__html: INFO.experience.description,
									}}
								></div>
							</div>

							<div className="experiences-left-side">
								<div className="experiences-image-container">
									<div className="experiences-image-wrapper">
										<img
											src="about.svg"
											alt="about"
											className="experiences-image"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="projects-container">
						{/* <div
							className="title"
							style={{ fontSize: 25, marginTop: 15 }}
						>
							Work Experience
						</div> */}
						<div className="projects-wrapper">
							{myExperience.map((project, index) => (
								<div
									className="projects-project"
									key={(index + 1).toString()}
								>
									<Experience
										key={(index + 1).toString()}
										date={project().date}
										position={project().position}
										title={project().title}
										description={project().description}
										skills={project().skills}
										logo={project().logo}
										width={project().width}
										height={project().height}
									/>
								</div>
							))}
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default WorkExperience;