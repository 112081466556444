import "./escpt.css";

const ESCPT = (props) => {
	return (
		<html>
			<head>
				<meta
					http-equiv="Content-Type"
					content="text/html; charset=utf-8"
				/>
				<title>
					🚽 Enhancing Sanitation by Capacity Planning of Toilets
				</title>
			</head>
			<body>
				<article
					id="02967b1f-6d87-4c23-9e9a-0c80b25a1069"
					className="page sans"
				>
					<header>
						<div className="page-header-icon undefined">
							<span className="icon">📍</span>
						</div>
						<h1 className="page-title">
							<strong>
								<strong>
									🚽 Enhancing Sanitation by Capacity Planning
									of Toilets
								</strong>
							</strong>
						</h1>
						<p className="page-description"></p>
					</header>
					<div className="page-body">
						<h3 id="0deafa55-ced7-4eb2-955f-37120d0246c4" className="">
							🚑 <strong>Scope</strong>
						</h3>
						<p id="78af8c42-07b0-4558-be47-8f439702d621" className="">
							<strong>Problem Statement</strong>
						</p>
						<ul
							id="99e19a06-58d2-4ee2-904c-f529fac639fa"
							className="bulleted-list"
						>
							<li>
								<strong>Sanitation Crisis in India</strong>:
								India faces a critical challenge with 60% of its
								population lacking proper sanitation, leading to
								widespread open defecation.
							</li>
						</ul>
						<ul
							id="581c8517-46b0-49fb-bed8-8fd1e58d6278"
							className="bulleted-list"
						>
							<li>
								<strong>Inadequate Toilet Facilities</strong>:
								Despite government initiatives like Swachh
								Bharat, there&#x27;s a significant gap in toilet
								availability, especially in high-density areas.
							</li>
						</ul>
						<p id="06b603bd-67b4-4677-9af8-69909db59c68" className="">
							<strong>Proposed Solution</strong>
						</p>
						<ul
							id="8106b9c7-84b2-4d26-8c1d-247c86fa765c"
							className="bulleted-list"
						>
							<li>
								<strong>Toilet Capacity Planning</strong>:
								Utilizing machine learning and image processing
								to identify regions with the most acute need for
								toilets based on population density and current
								toilet numbers.
							</li>
						</ul>
						<ul
							id="4a2342ab-ba5c-4fb5-96cd-10bb64742fb6"
							className="bulleted-list"
						>
							<li>
								<strong>Data-Driven Construction</strong>:
								Gathering data on population, sewage, water, and
								electricity availability to determine feasible
								locations for new toilets.
							</li>
						</ul>
						<ul
							id="884f6b6a-4c30-4780-beba-6c8f6bf1e48d"
							className="bulleted-list"
						>
							<li>
								<strong>Optimized Toilet Placement</strong>:
								Recommending construction sites by overlaying
								essential services availability with vacant
								land, ensuring maximum utility and
								accessibility.
							</li>
						</ul>
						<h3 id="33adbb43-3894-4eb0-988d-6ae49abe0f89" className="">
							<strong>
								📊 Data Collection and Analysis for Mumbai City
								POC
							</strong>
						</h3>
						<ul
							id="64318997-0fb3-489c-a4ae-b619e61dcf22"
							className="bulleted-list"
						>
							<li>
								<strong>
									Geo-Location and Infrastructure Data
								</strong>
								: Gathered exact locations of existing public
								toilets, along with population density data in
								key areas of Mumbai.
							</li>
						</ul>
						<ul
							id="c5f7fd9f-693f-4ef4-a689-66d3651fd0a0"
							className="bulleted-list"
						>
							<li>
								<strong>Comprehensive EDA</strong>: Identified
								areas with toilet scarcity, especially near
								railway stations, bus stops, and markets.
							</li>
						</ul>
						<ul
							id="3fb515e7-6dd2-4f6d-bb30-1f95ceda7977"
							className="bulleted-list"
						>
							<li>
								<strong>
									Mapping and Feasibility Analysis
								</strong>
								: Utilized ArcGIS for layering population data,
								toilet locations, and water/sewage
								infrastructure to pinpoint potential toilet
								sites.
							</li>
						</ul>
						<ul
							id="d04bb2bc-d0b0-43aa-847f-e037186aa0cf"
							className="bulleted-list"
						>
							<li>
								<strong>
									Population Estimation Techniques
								</strong>
								: Combined live Google Maps data and image
								processing for accurate population counts,
								considering both residents and floating
								populations.
							</li>
						</ul>
						<blockquote
							id="903fb8a7-c095-4a05-9c7e-0a4fc02bcf23"
							className="block-color-teal"
						>
							By integrating geographical, demographic, and
							infrastructural data, we could strategically plan
							toilet locations to address the most critical needs
							in Mumbai&#x27;s densely populated areas.
						</blockquote>
						<h3 id="81330a1c-2371-438e-bb6c-2de9a3bd1df6" className="">
							<strong>💻 Model Development and Solution</strong>
						</h3>
						<ul
							id="f7545a1d-1219-4300-bf37-3f933a6b4b3b"
							className="bulleted-list"
						>
							<li>
								<strong>Decision Tree Algorithm</strong>:
								Utilized for pinpointing appropriate toilet
								locations based on various criteria.
							</li>
						</ul>
						<ul
							id="3318122f-895a-484c-956f-184b2f21a71b"
							className="bulleted-list"
						>
							<li>
								<strong>
									Computer Vision and Image Processing
								</strong>
								: Employed to analyze population density and
								identify potential toilet sites.
							</li>
						</ul>
						<ul
							id="495a0a86-588a-492a-8a5a-8cdbf952158c"
							className="bulleted-list"
						>
							<li>
								<strong>
									Interactive Website for Officials
								</strong>
								: Developed a tool displaying a color-coded map
								indicating areas needing toilets, with red grids
								signaling high priority areas.
							</li>
						</ul>
						<ul
							id="6f791602-9083-4dfe-9f95-c5580b8fd424"
							className="bulleted-list"
						>
							<li>
								<strong>Comprehensive Visualization</strong>:
								Provided detailed graphs and data
								visualizations, offering government officials
								insightful information for decision-making.
							</li>
						</ul>
						<blockquote
							id="7b0de742-92a1-4fb3-beb4-abb1df746e97"
							className="block-color-blue"
						>
							Our model combines decision trees and image
							processing to guide efficient toilet placement,
							presented through an intuitive website that helps
							officials in making data-backed sanitation
							decisions.
						</blockquote>
						<h3 id="3e7ea42b-6d2a-4991-89fe-08090ebbb01b" className="">
							<strong>
								🚀 Innovative Contributions, Market Potential,
								and Future Scope
							</strong>
						</h3>
						<ul
							id="039ff54d-66de-4756-93ea-359876e254d9"
							className="bulleted-list"
						>
							<li>
								<strong>
									Resourceful and Scalable Solution
								</strong>
								: Our project innovatively integrates decision
								trees and computer vision to identify critical
								areas for toilet construction in Mumbai,
								enhancing sanitation and public health.
							</li>
						</ul>
						<ul
							id="bae85afe-8557-481c-a8c3-2d282d9e3334"
							className="bulleted-list"
						>
							<li>
								<strong>Significant Market Reach</strong>: With
								a focus on densely populated urban areas, the
								project aligns with national initiatives like
								Swachh Bharat, addressing a vast market of
								governmental bodies.
							</li>
						</ul>
						<ul
							id="95cf05d7-7963-4c2b-bcc3-081c2474b80e"
							className="bulleted-list"
						>
							<li>
								<strong>Long-term Vision</strong>: We aim to
								scale this model nationwide, continuously
								refining our tools and methodologies to adapt to
								changing demographics and infrastructure needs.
							</li>
						</ul>
						<blockquote
							id="182d8571-0ea8-4e68-834a-296bdb35de8a"
							className="block-color-brown"
						>
							This initiative represents a blend of technology and
							civic responsibility, poised to make a significant
							impact on sanitation management across India.
						</blockquote>
						<p
							id="6278ee21-ba87-4654-adf8-53b88dd981da"
							className=""
						></p>
					</div>
				</article>
				<span
					className="sans"
				></span>
			</body>
		</html>
	);
};

export default ESCPT;
