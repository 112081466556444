import "./gpp.css";

const GPP = (props) => {
	return (
		<html>
			<head>
				<meta
					http-equiv="Content-Type"
					content="text/html; charset=utf-8"
				/>
				<title>🌿 Spot Garbage - Garbage Profiling Project</title>
			</head>
			<body>
				<article
					id="487dd4e9-c6bc-48dc-bb13-7b96abe56f72"
					className="page sans"
				>
					<header>
						<div className="page-header-icon undefined">
							<span className="icon">♻️</span>
						</div>
						<h1 className="page-title">
							<strong>
								<strong>
									🌿 Spot Garbage - Garbage Profiling Project
								</strong>
							</strong>
						</h1>
						<p className="page-description"></p>
					</header>
					<div className="page-body">
						<h3 id="b68359cf-2fe3-4c82-bd57-b40c805cfcde" className="">
							<strong>🌟 Scope</strong>
						</h3>
						<p id="d26c2c7e-f0c5-4ab0-9b5c-95aa6e0532af" className="">
							<strong>
								Addressing the Waste Management Crisis in Indian
								Cities
							</strong>
						</p>
						<p
							id="810dd141-4581-4e98-8f01-4269f16a9039"
							className="block-color-default"
						>
							&quot;Spot Garbage&quot; emerges as a vital solution
							in the context of India&#x27;s growing waste
							management challenges. The project is designed to
							address several key issues:
						</p>
						<ol
							type="1"
							id="51378f27-bbae-4202-b32a-e0c256c1c63a"
							className="numbered-list"
							start="1"
						>
							<li>
								<strong>Increasing Urban Waste</strong>: With
								population growth and limited land availability,
								the disposal of vast amounts of waste has become
								a daunting task.
							</li>
						</ol>
						<ol
							type="1"
							id="97970299-4de8-47cc-8191-6d6802559749"
							className="numbered-list"
							start="2"
						>
							<li>
								<strong>
									Government Initiatives and Compliance
								</strong>
								: In response to these challenges, the Swachh
								Bharat Abhiyan mandates segregation of waste
								into dry, wet, and e-waste categories. However,
								adherence to these guidelines is inconsistent
								across societies.
							</li>
						</ol>
						<ol
							type="1"
							id="83b980df-84dd-4b05-89f4-9732485c103b"
							className="numbered-list"
							start="3"
						>
							<li>
								<strong>
									Public Awareness and Participation
								</strong>
								: A significant barrier is the lack of awareness
								among citizens regarding proper waste
								segregation practices at home.
							</li>
						</ol>
						<blockquote
							id="076ee538-32fa-4cd3-8973-f060548d1676"
							className="block-color-red"
						>
							Addressing the escalating waste management crisis,
							this project utilizes artificial intelligence and
							image processing to enhance waste segregation and
							disposal processes.
						</blockquote>
						<h3 id="8098bad4-ef5b-451d-b154-1ddabe1f2ad7" className="">
							📊 <strong>Data Collection and Processing</strong>
						</h3>
						<ul
							id="135b31a6-129b-4802-8fd1-bf09462d75d0"
							className="bulleted-list"
						>
							<li>
								<strong>Innovative Data Collection</strong>: We
								developed a tool for public contributions to our
								unique dataset, involving image uploads of
								various waste types.
							</li>
						</ul>
						<blockquote
							id="b26c8c0c-163e-4f92-b3c0-94d117088a9b"
							className="block-color-teal"
						>
							<strong>Data Cleaning and Labeling</strong>:
							Rigorous data processing involved labeling 10,000+
							images with 25 distinct classNamees, like plastic
							bottles and e-waste.
						</blockquote>
						<h3 id="34154de0-4c84-4fd2-ba74-b2b5ea4cb074" className="">
							💻<strong>AI Model Development and Testing</strong>
						</h3>
						<ul
							id="b2474882-de80-48ec-aec0-a1848a1e0acc"
							className="bulleted-list"
						>
							<li>
								<strong>Exploring Multiple Algorithms</strong>:
								We experimented with various
								{" "}<mark className="highlight-teal_background">
									object detection models including YOLO,
									RCNN, SSD, and Faster RCNN
								</mark>{" "}
								. Each algorithm was rigorously tested for its
								effectiveness in waste classNameification.
							</li>
						</ul>
						<ul
							id="c378a876-171e-4618-a376-953246dca5ba"
							className="bulleted-list"
						>
							<li>
								<strong>Comparative Analysis</strong>: Our
								evaluation focused on metrics like accuracy,
								processing speed, and the ability to detect
								small to large objects. This comprehensive
								testing was crucial to determine the most
								suitable model for our application.
							</li>
						</ul>
						<blockquote
							id="a8800e4f-3e50-4a5a-a7e8-c1dbb180b009"
							className="block-color-blue"
						>
							<strong>Finalizing on Faster RCNN</strong>: Based on
							the benefits, results, and higher accuracy in
							detecting a wide range of object sizes, Faster RCNN
							was chosen as our primary algorithm.
						</blockquote>
						<h3 id="40a269dd-7d58-4aa5-a67c-a46b9efa044f" className="">
							🎮{" "}
							<strong>Gamification and Citizen Engagement</strong>
						</h3>
						<ul
							id="40445ba0-1d1c-47f3-87f8-a278bff240d2"
							className="bulleted-list"
						>
							<li>
								<strong>Interactive Application</strong>:
								Developed a gamified application where citizens
								can identify waste types and earn rewards,
								encouraging widespread participation.
							</li>
						</ul>
						<ul
							id="7dc30de5-cc40-466c-8a4b-e75899c95623"
							className="bulleted-list"
						>
							<li>
								<strong>Community Rating System</strong>:
								Implemented a system for rating communities
								based on their waste segregation practices.
							</li>
						</ul>
						<h3 id="9145b7aa-e958-4735-928d-f45f2ece0a02" className="">
							🚀 <strong>Technical Infrastructure</strong>
						</h3>
						<ul
							id="ea85bc05-de5d-45e4-8c9f-24ebf26b3eba"
							className="bulleted-list"
						>
							<li>
								<strong>Robust Backend Systems</strong>:
								Building the
								{" "}<mark className="highlight-teal_background">
									backend with Node.js, Express, and Python,{" "}
								</mark>{" "}
								creating a strong foundation for data processing
								and API management.
							</li>
						</ul>
						<ul
							id="7f16a5c1-964a-451b-8e3d-f8d788fa8851"
							className="bulleted-list"
						>
							<li>
								<strong>Responsive Frontend Development</strong>
								: Implementing
								{" "}<mark className="highlight-teal_background">
									React.js
								</mark>{" "}{" "}
								for a user-friendly and interactive application
								interface.
							</li>
						</ul>
						<ul
							id="228428b0-0394-4348-b358-f6779861f0cd"
							className="bulleted-list"
						>
							<li>
								<strong>Integration of IoT Devices</strong>:
								Incorporating
								{" "}<mark className="highlight-teal_background">
									IoT technology
								</mark>{" "}
								{" "}<mark className="highlight-default"> </mark>{" "}for
								real-time waste segregation monitoring by local
								garbage collectors.
							</li>
						</ul>
						<h3 id="53214724-661d-4039-839c-a57281d39faa" className="">
							<strong>
								🌱 Future Scope and Environmental Impact
							</strong>
						</h3>
						<ul
							id="accbfed6-7085-468b-be15-a5f0d7a5ca48"
							className="bulleted-list"
						>
							<li>
								<strong>
									Enhanced Learning and Algorithm Development
								</strong>
								: Aiming for continual model improvement through
								user feedback and creating custom classNameification
								algorithms for greater precision.
							</li>
						</ul>
						<ul
							id="f1c805fc-ae29-49cc-9c1c-97076822ac8e"
							className="bulleted-list"
						>
							<li>
								<strong>
									Nationwide Implementation and Startup
									Aspiration
								</strong>
								: Planning to scale up the project for
								widespread use across India, aligning with
								national waste management goals.
							</li>
						</ul>
						<ul
							id="0e879ead-6554-479a-a8e4-b852d3f98606"
							className="bulleted-list"
						>
							<li>
								<strong>
									Positive Environmental Contribution
								</strong>
								: This initiative directly supports eco-friendly
								waste management, contributing to a sustainable
								and cleaner environment.
							</li>
						</ul>
					</div>
				</article>
				<span
					className="sans"
				></span>
			</body>
		</html>
	);
};

export default GPP;
