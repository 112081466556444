import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import INFO from "../data/user";
import myProjects from "../data/projects";
import "./styles/readProject.css";
import "react-notion/src/styles.css";
import "prismjs/themes/prism-tomorrow.css";
import Weather from "../data/projects/wbstlf/wbstlf";
import USAPA from "../data/projects/usapa/usapa";
import Aarogya from "../data/projects/aarogya/aarogya";
import ESCPT from "../data/projects/escpt/escpt";
import GPP from "../data/projects/gpp/gpp";
import ILS from "../data/projects/ils/ils";
import Safeq from "../data/projects/safeq/safeq";
import Stockit from "../data/projects/stockit/stockit";
import USCIA from "../data/projects/uscia/uscia";
import USPDP from "../data/projects/uspdp/uspdp";

const DynamicPageComponent = (props) => {
	const componentMapping = {
		usapa: USAPA,
		wbstlf: Weather,
		aarogya: Aarogya,
		escpt: ESCPT,
		gpp: GPP,
		ils: ILS,
		safeq: Safeq,
		stockit: Stockit,
		uscia: USCIA,
		uspdp: USPDP,
	};

	const DynamicComponent = componentMapping[props.page];

	return <DynamicComponent />;
};

const ReadProject = () => {
	const navigate = useNavigate();
	let { slug } = useParams();
	const project = myProjects[slug - 1];
	const [page, setPage] = useState(null);

	useEffect(() => {
		window.scrollTo(0, 0);
		setPage(project().page);
	}, [project]);

	return (
		<React.Fragment>
			<Helmet>
				<title>{`${project().title} | ${INFO.main.title}`}</title>
				<meta name="description" content={project().description} />
			</Helmet>

			<div className="page-content">
				<NavBar />

				<div className="content-wrapper">
					<div className="read-project-logo-container">
						<div className="read-project-logo">
							<Logo width={46} />
						</div>
					</div>

					<div className="read-project-container">
						<div className="read-project-back">
							<img
								src="../back-button.png"
								alt="back"
								className="read-project-back-button"
								onClick={() => navigate(-1)}
							/>
						</div>

						<div className="read-project-wrapper">
							{page ? <DynamicPageComponent page={page} /> : null}
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default ReadProject;
