function project_1() {
	return {
		image: "wbstlf.svg",
		title: "🌦️📊🔌 Weather based short-Term load forecasting system",
		tags: ["Deep Learning", "LSTM & ANN", "AWS Deployment", "React Interface"],
		page: "wbstlf",
		description:
			"In partnership with TATA Power and the India Meteorological Department, our project developed a sophisticated short-term load forecasting system tailored to India's diverse climatic and festive landscape. Utilizing a decade's worth of electricity usage and weather data, we employed exploratory data analysis (EDA) and deep learning techniques, including LSTM and ANN models, to accurately predict daily power requirements. This system, demonstrating high accuracy and efficiency, was deployed seamlessly on AWS with a React-based interface, significantly improving power demand management.",
	};
}

function project_2() {
	return {
		image: "usapa.svg",
		title: "🌾 Unified System for Agricultural Prediction using AI/ML",
		tags: ["AI in Agriculture", "Predictive Analytics", "React Dashboard", "AWS & CI/CD Deployment"],
		page: "usapa",
		description:
			"Our project, recognized at the Smart India Hackathon 2020, revolutionized Indian agriculture by blending AI with extensive agricultural data analysis. We developed a predictive system utilizing weather, crop production, and rate data spanning several decades. This system, featuring a sophisticated React dashboard for visualization and analysis, enables precise agricultural forecasts, aiding in effective resource allocation and decision-making. Deployed on AWS with a robust CI/CD pipeline, our solution enhanced operational efficiency and achieved an impressive ~95.67% accuracy in predictions, significantly impacting agricultural planning and budgeting."
	};
}
function project_3() {
	return {
		image: "gpp.svg",
		title: "🌿 Spot Garbage - Garbage Profiling Project ",
		tags: ["AI & Image Processing", "Faster RCNN", "React & Node.js", "IoT Integration"],
		page: "gpp",
		description:
		"Spot Garbage is an innovative project addressing India's waste management crisis by integrating AI and image processing for efficient waste segregation. Leveraging a diverse dataset of over 10,000 images, we developed an AI model utilizing Faster RCNN for accurate waste classification. The project includes a gamified application to engage citizens in waste segregation, enhanced by a robust technical infrastructure featuring Node.js, Express, Python, and React.js, along with IoT device integration. With ambitions for nationwide implementation, Spot Garbage aims to make a substantial environmental impact and align with India's waste management goals."
	};
}
function project_4() {
	return {
		image: "escpt.gif", //https://storyset.com/illustration/environmental-audit/amico/animate
		title: "🚽 Enhancing Sanitation by Capacity Planning of Toilets",
		tags: ["Machine Learning", "Image Processing", "ArcGIS Mapping", "Public Health & Sanitation"],
		page: "escpt",
		description:
			"Our project tackles India's sanitation crisis by using machine learning and image processing for strategic toilet capacity planning in densely populated areas. Focusing on Mumbai as a proof of concept, we collected and analyzed geo-location, infrastructure, and population data, utilizing ArcGIS for mapping potential toilet sites. We developed a model employing decision trees and computer vision, culminating in an interactive website that provides a color-coded map to assist officials in prioritizing toilet construction. This scalable solution aligns with national initiatives like Swachh Bharat, aiming for nationwide implementation to significantly improve public health and sanitation."
	};
}
function project_5() {
	return {
		image: "ils.gif", //https://storyset.com/illustration/login/bro/animate
		title: "🚪 Ingenious Lock System",
		tags: ["Smart Lock Technology", "Facial & Voice Recognition", "IoT & PWA Integration", "Home Security Innovation"],
		page: "ils",
		description:
			"The Ingenious Lock System is a cutting-edge smart lock designed to revolutionize home security. It features multilingual voice unlock, dynamic keypad security, one-shot facial recognition, and remote monitoring capabilities. This advanced system, built on IoT infrastructure, integrates voice and facial recognition models like VGG-Face and DeepSpeech, ensuring high accuracy and user-friendliness. The lock is further enhanced with a comprehensive PWA web app for remote home management. Future expansions include advanced biometric integration and AI-powered intruder alerts, positioning the system as a global leader in smart home security."
	};
}
function project_6() {
	return {
		image: "uspdp.png",
		title: "📦 Unified System for Package Damage Prediction using AI",
		tags: ["Predictive Analysis", "Machine Learning", "GCP Cloud Deployment", "Package Damage Reduction"],
		page: "uspdp",
		description:
			"Our project addresses the significant issue of damaged packages in UPS deliveries, where out of 70 million yearly deliveries, approximately 600,000 packages are damaged. We developed a smart predictive analysis system to forecast potential package damages, enhancing customer satisfaction and reducing operational losses. Utilizing extensive delivery data, we performed in-depth exploratory data analysis (EDA) and tested diverse algorithms, including LSTM, GRU, ARIMA, and Prophet, achieving high accuracy in predictions. The final model was deployed on GCP Cloud Run, with advanced features like anomaly detection and probabilistic forecasts, significantly optimizing UPS's hub performance and operational planning."
	};
}
function project_7() {
	return {
		image: "uscia.gif", //https://storyset.com/illustration/marketing-consulting/amico/animate,
		title: "📈 Unified System for Competitive Intelligence using AI",
		tags: ["AI-Driven Competitive Intelligence", "Generative AI & Chatbot", "Google Cloud Deployment", "Strategic Business Analytics"],
		page: "uscia",
		description:
			"USCIA is an AI-driven competitive intelligence solution developed to save companies approximately $600,000 annually spent on third-party tools. It efficiently analyzes competitor performance, featuring customized dashboards, daily AI-assisted insights, and self-serve analytics for departments like Corporate Strategy, Sales, and Marketing. The solution aggregates and processes vast data, including news, financial records, and social media analytics. Key features include a Generative AI for summarization and sentiment analysis, an interactive AI assistant chatbot, and in-depth financial and social media insights. Deployed on Google Cloud with future enhancements planned, USCIA transforms competitive intelligence into actionable business strategies."
	};
}
function project_8() {
	return {
		image: "aarogya.gif", // https://storyset.com/illustration/mental-health/rafiki
		title: "🧠 Aarogya: AI-Powered Mental Health Solution",
		tags: ["AI in Mental Health", "NLP & Data Mining", "Depression Detection", "React & Cloud Integration"],
		page: "aarogya",
		description:
			"Aarogya is a groundbreaking AI-powered platform designed to detect early signs of depression and intervene in mental health crises. Developed in response to a tragic incident at UB, it leverages advanced Natural Language Processing, Data Mining, and Machine Learning, combined with hybrid cloud technologies. The platform analyzes data from social media, psychological questionnaires, and therapist conversations. It has successfully integrated NLP models like BERT and DistilBERT, offering insights from Twitter and therapist dialogues. Aarogya, prototyped with a React-based frontend and cloud backend, represents a significant step in mental health care, combining technological innovation with societal impact."
	};
}
function project_9() {
	return {
		image: "safeq.gif",
		title: "🦠 Covid-19 SafeQ",
		tags: ["Virtual Queue Management", "Covid-19 Solution", "Mobile App Development", "Social Distancing Technology"],
		page: "safeq",
		description:
			"Covid19 SafeQ is an innovative mobile application designed to address the challenges of social distancing during the Covid-19 pandemic. With the restriction of essential shops to just two hours of operation, this app enables users to virtually queue for their shopping needs. Users can select shops on a map and receive a virtual ticket with a designated shopping time, thereby eliminating physical queues and adhering to social distancing norms. Integrated with cloud services for efficient queue management and a user-friendly interface, Covid19 SafeQ also has the potential to be linked with the Arogya Setu app for enhanced safety. This eco-friendly and health-conscious solution significantly contributes to community health and pandemic management."
	};
}
function project_10() {
	return {
		image: "stockit.gif",
		title: "🌐 Stockit - Smart Inventory Management System",
		tags: ["Automated Inventory Management", "React & Neo4j Integration", "Cloud Deployment", "Barcode Tracking System"],
		page: "stockit",
		description:
			"Our project revolutionizes conventional inventory management with a React-based web application that automates and streamlines the process for wholesalers and retailers. This solution features a dynamic, user-friendly interface developed in React, secure data management with Firebase and Neo4j for enhanced inventory visualization, and a barcode system for precise product tracking. The application, cloud-deployed for robust performance, offers dual user profiles, an automated ordering process, and graph-based inventory management. Future enhancements include AI integration for predictive analytics and exploring blockchain for transactional security and transparency, significantly modernizing the inventory management landscape."
	};
}

const myProjects = [
	project_1,
	project_2,
	project_3,
	project_4,
	project_5,
	project_6,
	project_7,
	project_8,
	project_9,
	project_10,
];

export default myProjects;
