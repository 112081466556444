import React from "react";
import INFO from "../../data/user";

import "./styles/education.css";

const Education = (props) => {
	const { date, title, description, logo, courses } = props;

	return (
		<React.Fragment>
			<div className="education">
				<div className="education-left-side">
					<img
						src={INFO.education[logo]}
						alt="logo"
						className="education-logo"
					/>
					<div className="education-date">{date}</div>
				</div>

				<div className="education-right-side">
					<div className="education-title">{title}</div>
					<div className="education-description">{description}</div>
					<div className="education-courses">{courses}</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Education;