function experience_1() {
    return {
        date: "May 2022 - Present",
        title: "Data Scientist @ UPS",
        logo: "ups",
        description: `
        <ul>
        <li>Utilized Google's LLM, Roberta, and Nearest Neighbors Algorithm to develop a chatbot for seamless data
        warehouse table retrieval, achieving a 30% increase in data accessibility and a 25% reduction in search time.</li>
        <li>Leveraged 1.2TB historical data to engineer a package damage forecasting model in Python with various
        regression, time-series, and statistical algorithms, achieving 87% accuracy.</li>
        <li>Implemented high recall anomaly detection and alert system, saving ~$2M in package damage costs for UPS,
        while improving customer satisfaction. Created PowerBI dashboards for 650+ US hubs to facilitate data-driven
        decisions.</li>
        <li>Expertly orchestrated end-to-end ETL and model training pipeline, harnessing GCP, Python, and SQL.</li>
        </ul>`,
        skills: ["Python", "SQL", "Google Cloud Platform", "GenAI", "MLOps", "Kubeflow", "PowerBI"],
        width: "75px",
        height: "75px"
    };
}

function experience_2() {
    return {
        date: "May 2022 - Present",
        title: "Machine Learning Engineer Intern @ Tata Power",
        logo: "tata_power",
        description: `
        <ul>
        <li>Created a high-accuracy weather-based load forecasting system using Artificial Neural Network, cutting energy
        costs by 10% and reducing customer disruptions by 5%.</li>
        <li>Conducted comprehensive EDA on 3M data points, analyzing consumption patterns and assessing external
        factors' impact on daily electricity usage, leading to a 94% accuracy rate.</li>
        <li>Utilized Python, SQL, and Tableau to evaluate seasonal trends in electrical load to reduce the MAE by 25%.</li>
        <li>Managed prototype deployment, monitoring model performance, and data drift. Automated CI/CD pipeline via
        GitHub Actions and AWS, cutting manual work by 30%.</li>
        </ul>`,
        skills: ["Python", "SQL", "FastAPI", "AWS", "Forecasting", "ReactJS"],
        width: "135px",
        height: "95px"
    };
}

function experience_3() {
    return {
        date: "May 2022 - Present",
        title: "Data Scientist Intern @ India Meteriological Departent",
        logo: "imd",
        description: `
        <ul>
        <li>Spearheaded a team of 4 to build and deploy a live sensor-driven weather prediction model, reaching a 92%
        accuracy rate in forecasting humidity and temperature using Python, TensorFlow, and SQL.</li>
        <li>Achieved an 80% success rate in A/B testing for new features before their production release, highlighting
        effective decision-making and product enhancement strategies.</li>
        </ul>`,
        skills: ["Python", "SQL", "FastAPI", "AWS", "Forecasting", "ReactJS"],
        width: "95px",
        height: "165px"
    };
}

const myExperience = [experience_1, experience_2, experience_3 ];

export default myExperience;