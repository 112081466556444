import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import ReactGA from "react-ga4";

import Homepage from "./pages/homepage";
import About from "./pages/about";
import Blogs from "./pages/blogs";
import Projects from "./pages/projects";
import ReadProject from "./pages/readProject";
import Contact from "./pages/contact";
import Notfound from "./pages/404";
import WorkExperience from "./pages/experience";
import Resume from "./pages/resume";

import { TRACKING_ID } from "./data/tracking";
import "./app.css";
import "react-notion/src/styles.css";
import "prismjs/themes/prism-tomorrow.css";

function App() {
	useEffect(() => {
		if (TRACKING_ID !== "") {
			ReactGA.initialize(TRACKING_ID);
		}
	}, []);

	return (
		<div className="App">
			<Routes>
				<Route path="/" element={<Homepage />} />
				<Route path="/about" element={<About />} />
				<Route path="/experience" element={<WorkExperience />} />
				<Route path="/blogs" element={<Blogs />} />
				<Route path="/projects" element={<Projects />} />
				<Route path="/project/:slug" element={<ReadProject />} />
				<Route path="/contact" element={<Contact />} />
				{/* <Route path="*" element={<Resume />} /> */}
			</Routes>
		</div>
	);
}

export default App;
