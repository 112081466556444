import "./wbstlf.css";

const Weather = (props) => {
	return (
		<html>
			<head>
				<meta
					http-equiv="Content-Type"
					content="text/html; charset=utf-8"
				/>
				<title>
				🌦️📊🔌 Weather based short-Term load forecasting system
				</title>
			</head>
			<body>
				<article
					id="d9caf463-e04b-4ed9-a784-c8bd478f0c1b"
					className="page sans"
				>
					<header>
						<div className="page-header-icon undefined">
							<span className="icon">🌅</span>
						</div>
						<h1 className="page-title">
							<strong>
								<strong>
								🌦️📊🔌 Weather based short-Term load forecasting
									system
								</strong>
							</strong>
						</h1>
						<p className="page-description"></p>
					</header>
					<div className="page-body">
						<p
							id="dd8a8c35-a2b2-48e1-a1ec-8d7e7375c0fd"
							className=""
						></p>
						<h2 id="3645f75b-1bb0-446d-afda-5204a8ca0752" className="">
							🚀 <strong>Scope</strong>
						</h2>
						<p id="45e936b6-4532-48e3-985e-2d6d66952bdc" className="">
							<strong>Collaboration for Demand Precision</strong>{" "}
							🤝🏻
						</p>
						<ul
							id="93034cd5-d1c9-4e07-94a5-37a0834c98aa"
							className="bulleted-list"
						>
							<li>
								In partnership with TATA Power and the India
								Meteorological Department, we addressed
								India&#x27;s dynamic electricity demands driven
								by climatic diversity and festivities.
							</li>
						</ul>
						<p id="e709d26f-2690-405b-8dbb-70fa4e10bc57" className="">
							<strong>Challenging Fluctuations</strong> ⚡
						</p>
						<ul
							id="24de4fd8-0e7c-4b2f-b49d-d1cd826e3f93"
							className="bulleted-list"
						>
							<li>
								India&#x27;s climate variations, marked by harsh
								seasons and year-round festivals, create
								fluctuating electricity needs. Demand surges
								during summers, plummets in winters, and peaks
								during celebrations.
							</li>
						</ul>
						<p id="edb1ca5c-b812-40ce-9c6f-b563b549f67f" className="">
							<strong>Precision is Paramount</strong> 💡
						</p>
						<ul
							id="2ef61028-595e-4225-9ea1-520bddaa0ee5"
							className="bulleted-list"
						>
							<li>
								To meet customers&#x27; needs, power companies
								must generate just the right amount daily.
							</li>
						</ul>
						<ul
							id="97a7060e-2775-403f-9169-190a71f0f4a1"
							className="bulleted-list"
						>
							<li>
								Generating less electricity than needed results
								in costly purchases from other companies,
								translating into higher bills for individual
								households.
							</li>
						</ul>
						<ul
							id="c1a74086-df93-41ca-a34b-520e07ff715d"
							className="bulleted-list"
						>
							<li>
								Conversely, generating excess power is wasteful,
								as there is no viable method for storing such
								surplus electricity for prolonged periods.
							</li>
						</ul>
						<p id="5cddffd3-a107-46bc-8263-9e176eeff771" className="">
							<strong>Driving Demand Efficiency</strong> ⚙️
						</p>
						<ul
							id="25ad9fff-a389-4545-bd04-6438010b4c88"
							className="bulleted-list"
						>
							<li>
								Consequently, a daily analysis is imperative,
								requiring domain experts to determine the exact
								amount of energy to be generated in power
								plants.
							</li>
						</ul>
						<blockquote
							id="41f7cbd7-d5ce-4f69-ab1b-d00ee9a42b53"
							className="block-color-red"
						>
							Our mission was to develop a load forecasting system
							that predicts the next day&#x27;s load requirement.
							We accomplished this by factoring in weather
							forecasts and holiday effects. 📈
						</blockquote>
						<h2 id="5fa4e798-1946-4981-a90f-33c735abb7d0" className="">
							📊 <strong>Data</strong>
						</h2>
						<blockquote
							id="795ed6e6-2e44-42f5-8621-01b13673ae44"
							className="block-color-teal"
						>
							We had 10 year of historic load generation data,
							recorded on a daily basis in 15-minute blocks,
							amounting to 96 blocks each day.
						</blockquote>
						<ul
							id="30323144-1f0e-4d32-a96c-43419fcf8b80"
							className="bulleted-list"
						>
							<li>
								For each day, corresponding weather data was
								available in hourly blocks, sourced from various
								weather stations.
							</li>
						</ul>
						<ul
							id="51db82a2-ae8a-40f9-af16-5f6c2c7758d4"
							className="bulleted-list"
						>
							<li>
								This extensive dataset encompassed data from 10
								major cities within a state in India.
							</li>
						</ul>
						<h2 id="8d7c0c61-2f17-416b-8917-86b6b9bb5356" className="">
							🔎 
							<strong>
								<strong>EDA (Exploratory Data Analysis)</strong>
							</strong>
						</h2>
						<ul
							id="e9358e3d-1bff-4eca-9f5a-ad45d445ec86"
							className="bulleted-list"
						>
							<li>
								Working with sensor-fetched weather data
								required significant data cleaning, imputation,
								and outlier removal. Merging the load generation
								and weather data in 15-minute blocks was a
								crucial step.
							</li>
						</ul>
						<ul
							id="c9370a46-185b-4c7a-a33c-79c72fd7b492"
							className="bulleted-list"
						>
							<li>
								During the EDA phase, we performed data
								profiling to investigate whether weather played
								a role in electricity usage.
							</li>
						</ul>
						<blockquote
							id="0be9107a-4973-4235-9860-21f59c96b62e"
							className="block-color-orange"
						>
							Our findings confirmed a direct correlation between
							weather conditions and power demand. We also
							analyzed the impact of holidays, discovering a
							similar direct correlation.
						</blockquote>
						<h2 id="cc8a66f5-caf5-4a8f-a6fc-7dc9db32eb1a" className="">
							<strong>🏋️ Model Training</strong>
						</h2>
						<ul
							id="d792fd0a-575b-4a1d-8165-7ff564608666"
							className="bulleted-list"
						>
							<li>
								With the prepared data, our model training
								journey began. Initially, we trained basic
								statistical models, achieving commendable
								accuracy.
							</li>
						</ul>
						<ul
							id="99a6fc8f-1061-4864-b3f0-a211267d8261"
							className="bulleted-list"
						>
							<li>
								Subsequently, we transitioned to deep learning
								models, specifically LSTM, which delivered an
								impressive 89% accuracy.
							</li>
						</ul>
						<blockquote
							id="4c35ad77-9a3b-49b3-861e-e29e478e875c"
							className="block-color-yellow"
						>
							Further improvement was attained with the training
							of an ANN, reaching an outstanding 94% accuracy.
							Deep learning models necessitated extensive
							preprocessing to ensure they received the right
							features.
						</blockquote>
						<h2 id="8e5b52c3-0d84-416d-ad3c-6b043c187fba" className="">
							🧪 <strong>Testing and Evaluation</strong>
						</h2>
						<ul
							id="2440f3a8-6b83-4494-a47a-fb3f98eb8322"
							className="bulleted-list"
						>
							<li>
								The culmination of our efforts led to the
								deployment of the model, accompanied by the
								development of a React-based frontend for the
								company&#x27;s use.
							</li>
						</ul>
						<blockquote
							id="d44f2d6f-7d09-4381-919c-75465014e369"
							className="block-color-blue"
						>
							We rigorously tested the model&#x27;s performance
							every day. We compared our predictions with real
							data to keep an eye on the Mean Absolute Error
							(MAE). The outcomes consistently demonstrated the
							effectiveness of our forecasting system.
						</blockquote>
						<h2 id="df4c9655-73c2-4c12-93fe-5ca153bdc129" className="">
							☁️ <strong>Deployment</strong>
						</h2>
						<blockquote
							id="671f609f-49e0-4096-832f-2ef7a28af17b"
							className="block-color-brown"
						>
							The entire solution was seamlessly deployed on AWS,
							featuring a robust CI/CD pipeline established
							through GitHub Actions.
						</blockquote>
						<ul
							id="4b0eaa05-da51-4402-8291-09233321e842"
							className="bulleted-list"
						>
							<li>
								This deployment not only ensured the reliability
								of the forecasting system but also facilitated
								its accessibility to the company and its
								stakeholders.
							</li>
						</ul>
						<h2 id="81c67bc7-c637-4abb-9f88-f42f05913e74" className="">
							🏆 Learnings and Achievements
						</h2>
						<ul
							id="eeb7b32a-7a2e-441f-9fdc-9ef1644a9a53"
							className="bulleted-list"
						>
							<li>
								Collaborated closely with domain experts during
								EDA, bridging technical and practical knowledge.
								Demonstrated adaptability as a cohesive team
								during model training, fostering open
								communication for effective problem-solving.💡🤝
							</li>
						</ul>
						<ul
							id="785418dc-d648-41e8-a0a3-b1576d487f54"
							className="bulleted-list"
						>
							<li>
								Collaborated closely with domain experts during
								EDA, bridging technical and practical knowledge.
								Demonstrated adaptability as a cohesive team
								during model training, fostering open
								communication for effective problem-solving.
								🧩👥
							</li>
						</ul>
						<p
							id="992b6967-d484-462e-b41b-a1f5596d114c"
							className=""
						></p>
						<h2 id="054770e5-a8cc-4049-8469-9982ee78ad6b" className="">
							🎥 Project Showcase
						</h2>
						<figure id="c23b1d60-f98c-4bf9-83ac-a9873d2fe386">
							<div className="source">
								https://drive.google.com/file/d/1Ee_gAZYDE2WJcmPvQYtSjnR5ZjoUY3U0/view?usp=sharing
							</div>
						</figure>
						<p
							id="5a874b05-7685-439d-991a-48ed45e88631"
							className=""
						></p>
					</div>
				</article>
				<span
					className="sans"
				></span>
			</body>
		</html>
	);
};

export default Weather;
