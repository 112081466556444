import "./stockit.css";

const Stockit = (props) => {
	return (
		<html>
			<head>
				<meta
					http-equiv="Content-Type"
					content="text/html; charset=utf-8"
				/>
				<title>🌐 Stockit - Smart Inventory Management System</title>
			</head>
			<body>
				<article
					id="44b97c81-f86d-477e-8920-8e17365e9cc9"
					className="page sans"
				>
					<header>
						<div className="page-header-icon undefined">
							<span className="icon">🔀</span>
						</div>
						<h1 className="page-title">
							<strong>🌐 </strong>Stockit - Smart
							<strong>Inventory Management System</strong>
						</h1>
						<p className="page-description"></p>
					</header>
					<div className="page-body">
						<h3
							id="b26605fb-0655-4054-ad1b-6f790d0c1475"
							className=""
						>
							<strong>🎯 Problem</strong>
						</h3>
						<ul
							id="27ada658-a5b2-4fe1-b7d9-6487aa810364"
							className="bulleted-list"
						>
							<li>
								Conventional inventory management is laborious
								and prone to errors, lacking real-time tracking
								and efficient order processing.
							</li>
						</ul>
						<h3
							id="34c4a677-6bc7-48ad-84e4-cf7c22c26327"
							className=""
						>
							<strong>🌟 Solution</strong>
						</h3>
						<ul
							id="12b21c38-350a-4d54-a483-0512a918fe34"
							className="bulleted-list"
						>
							<li>
								A React-based web application that automates
								inventory management, bridging wholesalers and
								retailers for streamlined product ordering and
								tracking.
							</li>
						</ul>
						<h3
							id="87a70f24-21cc-494d-8ee7-e44ce3ba50fd"
							className=""
						>
							<strong>💻 Technology Used and Deployment</strong>
						</h3>
						<ul
							id="652ca3cf-7de1-4e23-8127-1b34684e6c58"
							className="bulleted-list"
						>
							<li>
								<strong>Front-End Development</strong>:
								Implemented with React for a dynamic,
								user-friendly interface.
							</li>
						</ul>
						<ul
							id="89b2e200-130b-4161-9475-fa2e1b1693f1"
							className="bulleted-list"
						>
							<li>
								<strong>Database Management</strong>: Integrated
								Firebase for secure data storage and Neo4j for
								creating an inventory graph, enhancing data
								visualization and relationships.
							</li>
						</ul>
						<ul
							id="a11481f9-9064-4f48-a1ab-1cb7bd936ffd"
							className="bulleted-list"
						>
							<li>
								<strong>Barcode System</strong>: Incorporated
								barcode scanning for accurate product tracking
								from warehouse to sale.
							</li>
						</ul>
						<ul
							id="4e79abbf-a0a3-4e74-bdbb-89889c3873f6"
							className="bulleted-list"
						>
							<li>
								<strong>Cloud Deployment</strong>: Hosted the
								application on a scalable cloud platform for
								reliable access and performance.
							</li>
						</ul>
						<h3
							id="45392a28-7482-4737-bd07-9c8db4ffce5b"
							className=""
						>
							<strong>🔍 Features and Innovation</strong>
						</h3>
						<ul
							id="d4fd5714-917d-4dd1-9f46-6ff044c82e2b"
							className="bulleted-list"
						>
							<li>
								<strong>Dual User Profiles</strong>: Customized
								user experiences for wholesalers and retailers.
							</li>
						</ul>
						<ul
							id="6dc9f08e-e3d7-4130-bfe0-6f11320562ca"
							className="bulleted-list"
						>
							<li>
								<strong>Automated Ordering Process</strong>:
								Simplifies order placement and processing,
								reducing manual efforts.
							</li>
						</ul>
						<ul
							id="859840c7-f265-4525-af29-ed32edb7a0e2"
							className="bulleted-list"
						>
							<li>
								<strong>
									Graph-Based Inventory Management
								</strong>
								: Neo4j database enables a sophisticated
								representation of inventory, streamlining the
								tracking and allocation process.
							</li>
						</ul>
						<h3
							id="ae44d51f-b3c3-460e-b332-a6b20e6f8ac5"
							className=""
						>
							<strong>🚀 Future Scope</strong>
						</h3>
						<ul
							id="a069c6af-7ee8-420b-ac66-d69364d6d68d"
							className="bulleted-list"
						>
							<li>
								<strong>AI Integration</strong>: Planning to add
								AI algorithms for predictive analytics and
								demand forecasting.
							</li>
						</ul>
						<ul
							id="50c9bb9b-38ca-4243-b35e-955abc9c1540"
							className="bulleted-list"
						>
							<li>
								<strong>Blockchain Adoption</strong>: Exploring
								blockchain technology for enhanced security and
								transparency in transaction records.
							</li>
						</ul>
						<blockquote
							id="5939c495-b8d4-4475-ac50-44ed04300795"
							className="block-color-teal"
						>
							Our system modernizes inventory management, making
							it more efficient, accurate, and user-friendly.
						</blockquote>
						<p
							id="1ffc8a6f-c680-42a7-a7b0-531a6645779f"
							className=""
						></p>
					</div>
				</article>
				<span className="sans"></span>
			</body>
		</html>
	);
};

export default Stockit;
