import "./usapa.css";

const USAPA = (props) => {
	return (
		<html>
			<head>
				<meta
					http-equiv="Content-Type"
					content="text/html; charset=utf-8"
				/>
				<title>
					🌾 Unified System for Agricultural Prediction using AI/ML
				</title>
			</head>
			<body>
				<article
					id="7c89a003-b373-4a8c-9b59-483eace623d1"
					className="page sans"
				>
					<header>
						<div className="page-header-icon undefined">
							<span className="icon">🧑🏻‍🌾</span>
						</div>
						<h1 className="page-title">
							🌾 Unified System for Agricultural Prediction using
							AI/ML
						</h1>
						<p className="page-description"></p>
					</header>
					<div className="page-body">
						<h3
							id="e5181977-d3a0-4b26-91bc-12f023442cbf"
							className=""
						>
							🌏 <strong>Scope</strong>
						</h3>
						<p
							id="33ddcf6c-b191-4cfa-87e3-0047ca19a61b"
							className=""
						>
							In the backdrop of challenges in Indian agriculture,
							like fluctuating onion prices and the impact of
							monsoons on crop production, our project was
							conceived as a crucial intervention. The need to
							blend AI with agricultural data analysis emerged
							from the necessity to enhance budget planning and
							address the lack of a unified predictive system.
							This integration aimed to provide actionable
							insights to agricultural departments, enabling
							better resource allocation and decision-making at
							both federal and state levels.
						</p>
						<h3
							id="8d9fb8f6-d46f-446d-b145-424910073fdf"
							className=""
						>
							📊 <strong>Data Utilized</strong>
						</h3>
						<ul
							id="8b8bf194-2032-4fb5-b9fc-6b9c2dbed812"
							className="bulleted-list"
						>
							<li>
								<strong>Weather Data (1901-2002)</strong>:
								Included district-wise, monthly averages
								covering temperature ranges, cloud cover,
								precipitation, and evapotranspiration.
							</li>
						</ul>
						<ul
							id="eff397be-d2a3-42f6-87e8-29ff2820e42f"
							className="bulleted-list"
						>
							<li>
								<strong>Crop Production (1997-2019)</strong>:
								Yearly district-wise data with details on
								production, area, and yield, segmented by
								seasons.
							</li>
						</ul>
						<ul
							id="a73ba987-6847-4dc3-9db9-e65fcfdacca7"
							className="bulleted-list"
						>
							<li>
								<strong>Rates (2010-2020)</strong>: Daily
								center-wise wholesale and retail rates of
								commodities from selected centers.
							</li>
						</ul>
						<blockquote
							id="52450e6c-31df-4635-a83a-b2b72482c4f3"
							className="block-color-red"
						>
							This rich dataset allowed us to create a
							comprehensive predictive model addressing various
							aspects of agricultural production and market
							trends.
						</blockquote>
						<h3
							id="70e92412-6ded-4b26-9bb2-966fcb5f0bb1"
							className=""
						>
							🔍 <strong>Exploratory Data Analysis (EDA)</strong>
						</h3>
						<ul
							id="61491b27-b348-4cd1-9b38-e0999784658d"
							className="bulleted-list"
						>
							<li>
								<strong>Data Profiling</strong>: We conducted an 
								{" "}<mark className="highlight-teal_background">
									extensive profiling
								</mark>{" "}
								of the weather and crop production data. This
								involved
								{" "}<mark className="highlight-teal_background">
									assessing data quality, identifying missing
									values, and understanding distributions.
								</mark>{" "}
							</li>
						</ul>
						<ul
							id="76467037-4fe5-4ba5-bcac-17153fa33525"
							className="bulleted-list"
						>
							<li>
								<strong>Feature Engineering</strong>: We derived
								new features, such as aggregated weather
								patterns and crop seasonality indices, to enrich
								our models.
							</li>
						</ul>
						<ul
							id="e6f0b5db-d9bd-43a4-8747-6b3e940b74a8"
							className="bulleted-list"
						>
							<li>
								<strong>Correlation Analysis</strong>: Detailed
								correlation studies between weather parameters
								and crop yields were undertaken, identifying key
								predictors for crop success.
							</li>
						</ul>
						<blockquote
							id="4f25c63d-8c5d-4415-9ffb-a3dd898d9175"
							className="block-color-teal"
						>
							Our EDA was meticulous, focusing on uncovering
							hidden patterns and relationships, crucial for
							developing an accurate predictive model.
						</blockquote>
						<h3
							id="cbb954d1-0e40-4428-ad63-847bfd73bbdb"
							className=""
						>
							🖥️ <strong>Model Training and Development</strong>
						</h3>
						<ul
							id="ed77071b-2aff-471d-9004-7c3a78a5ec08"
							className="bulleted-list"
						>
							<li>
								<strong>Algorithm Exploration</strong>: We
								tested various machine learning algorithms,
								including
								{" "}<mark className="highlight-teal_background">
									deep learning{" "}
								</mark>{" "}
								techniques like
								{" "}<mark className="highlight-teal_background">
									LSTM{" "}
								</mark>{" "}
								for sequential weather data and ensemble methods
								like
								{" "}<mark className="highlight-teal_background">
									RandomForest, AdaBoost, XGBoost
								</mark>{" "}
								etc. for yield prediction.
							</li>
						</ul>
						<ul
							id="c5b19a25-ec47-424c-9480-48fc3d169bde"
							className="bulleted-list"
						>
							<li>
								<strong>
									Cross-Validation and Hyperparameter Tuning
								</strong>
								: Rigorous
								{" "}<mark className="highlight-teal_background">
									cross-validation
								</mark>{" "}
								methods were employed to prevent overfitting. We
								also conducted extensive
								{" "}<mark className="highlight-teal_background">
									hyperparameter tuning
								</mark>{" "}
								for optimizing model performance.
							</li>
						</ul>
						<ul
							id="e13e2159-e6f8-48db-854d-8184b82f1c9e"
							className="bulleted-list"
						>
							<li>
								<strong>Feature Importance Analysis</strong>:
								Post model training, we analyzed feature
								importance to understand the impact of different
								variables on our predictions.
							</li>
						</ul>
						<blockquote
							id="fc7f59a3-54fc-46b2-ae25-78094abff49e"
							className="block-color-orange"
						>
							Our model training phase was marked by a thoughtful
							approach, experimenting with a range of algorithms
							and techniques to ensure robustness and accuracy in
							our forecasts.
						</blockquote>
						<h3
							id="d759c8bf-2d27-4367-9d5f-44dadd541b10"
							className=""
						>
							<strong>📊 </strong>
							<strong>Data Visualization and Insights</strong>
						</h3>
						<ul
							id="2c98c38f-571e-4a95-bda4-305f3a469eef"
							className="bulleted-list"
						>
							<li>
								<strong>Interactive React Dashboard</strong>: We
								developed a sophisticated
								{" "}<mark className="highlight-teal_background">
									React-based dashboard
								</mark>{" "}
								enabling users to navigate through each state
								and district in India. This tool allowed for the
								visualization of predicted crop yields based on
								forecasted weather conditions, soil type, and
								other factors.
							</li>
						</ul>
						<ul
							id="8b052a41-320f-4999-8c2c-fa9eceaf6de8"
							className="bulleted-list"
						>
							<li>
								<strong>Price Prediction and Analysis</strong>:
								The dashboard also featured capabilities to view
								predicted prices for various crops, aiding in
								market trend analysis.
							</li>
						</ul>
						<ul
							id="295b36e0-4784-447f-b23e-6517c6586d77"
							className="bulleted-list"
						>
							<li>
								<strong>
									Government-Focused Reporting Tool
								</strong>
								: A significant aspect of our dashboard was its
								ability to allow government officials to compare
								historical data with predictions. This
								functionality was instrumental in compiling
								comprehensive reports for the upcoming
								year&#x27;s agricultural budgeting.
							</li>
						</ul>
						<blockquote
							id="0781ca8a-8973-4acd-87df-8cf25d84e725"
							className="block-color-blue"
						>
							Our React dashboard stood out as a pivotal tool, not
							only for visualizing agricultural forecasts but also
							for aiding government officials in strategic budget
							planning for agriculture.
						</blockquote>
						<h3
							id="41739003-2960-46c1-9f37-bb6f37056111"
							className=""
						>
							<strong>☁️ </strong>
							<strong>Deployment and CI/CD Pipeline</strong>
						</h3>
						<ul
							id="4a428d66-1159-4276-8b79-e438d94e4271"
							className="bulleted-list"
						>
							<li>
								<strong>Backend Deployment on AWS</strong>: The
								{" "}<mark className="highlight-teal_background">
									backend, built with FastAPI
								</mark>{" "}
								, was
								{" "}<mark className="highlight-teal_background">
									deployed on AWS Lambda
								</mark>{" "}
								,
								{" "}<mark className="highlight-teal_background">
									ensuring efficient scaling and management
								</mark>{" "}
								. All models and data were securely stored in
								AWS S3, providing robust and reliable data
								access.
							</li>
						</ul>
						<ul
							id="b62e1975-faa8-4762-85e7-00a34ebeb319"
							className="bulleted-list"
						>
							<li>
								<strong>Frontend Hosting on Vercel</strong>: The
								interactive frontend, designed for ease of use
								and accessibility, was hosted on Vercel,
								offering a seamless user experience.
							</li>
						</ul>
						<ul
							id="9e73adbc-9967-4dcf-8b44-3858854b551b"
							className="bulleted-list"
						>
							<li>
								<strong>
									Continuous Integration and Deployment
								</strong>
								: We established a
								{" "}<mark className="highlight-teal_background">
									Git-based CI/CD pipeline
								</mark>{" "}
								, enabling streamlined updates and maintenance
								of our codebase.
							</li>
						</ul>
						<ul
							id="2f93f57e-5f73-4db4-86fa-9ab3af6db4bc"
							className="bulleted-list"
						>
							<li>
								<strong>
									Model Retraining and Data Transformation
									Pipelines
								</strong>
								: We integrated
								{" "}<mark className="highlight-teal_background">
									SQL and Python-based pipelines
								</mark>{" "}
								for
								{" "}<mark className="highlight-teal_background">
									model retraining and data transformation
								</mark>{" "}
								, ensuring our system remained up-to-date with
								the latest data and trends.
							</li>
						</ul>
						<blockquote
							id="ccb5b428-41be-4c6e-a7ed-28fce70a1f06"
							className="block-color-teal"
						>
							Our deployment strategy focused on leveraging cloud
							technologies for scalability and efficiency, with a
							strong emphasis on continuous integration and
							deployment for ongoing system refinement.
						</blockquote>
						<h3
							id="432d08be-efd0-4e54-a9d9-d2c99a79081d"
							className=""
						>
							🏆 <strong>Achievements and Impact</strong>
						</h3>
						<ul
							id="29d2abe2-45d3-4814-899d-7f4145d73c5f"
							className="bulleted-list"
						>
							<li>
								<strong>
									National Recognition and High Precision
								</strong>
								: We were honored at the Smart India Hackathon
								2020 and achieved a remarkable
								{" "}<mark className="highlight-teal_background">
									~95.67% accuracy
								</mark>{" "}{" "}
								in our predictions.
							</li>
						</ul>
						<ul
							id="f5eff436-b6f6-4554-a823-b8cf040125b9"
							className="bulleted-list"
						>
							<li>
								<strong>Enhanced Operational Efficiency</strong>
								: Our project not only improved prediction
								accuracy but also
								{" "}<mark className="highlight-teal_background">
									boosted operational efficiency by 25%{" "}
								</mark>{" "}
								through advanced analytics and hyperparameter
								tuning.
							</li>
						</ul>
						<blockquote
							id="6c73190d-915a-4c54-be4c-b4e18b84dc55"
							className="block-color-brown"
						>
							Our project&#x27;s national recognition and high
							precision in predictions showcased our significant
							contribution to AI-driven agricultural innovation.
						</blockquote>
						<p
							id="b52001fa-16b9-4bdf-8e86-c8064c266749"
							className=""
						></p>
					</div>
				</article>
				<span className="sans"></span>
			</body>
		</html>
	);
};

export default USAPA;
