import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import AllBlogs from "../components/blogs/allBlogs";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/blogs.css";

const Blogs = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "blogs");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Projects | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="blogs" />
				<div className="content-wrapper">
					<div className="blogs-logo-container">
						<div className="blogs-logo">
							<Logo width={46} />
						</div>
					</div>
					<div className="blogs-container">
						<div className="projects-main">
							<div className="projects-right-side">
								<div className="title projects-title">
									🌟 Bits of Brilliance: Sharing Knowledge,
									Shaping Ideas
								</div>

								<div className="subtitle projects-subtitle">
									Welcome to "Bits of Brilliance" – a space
									where I unravel and share my knowledge and
									experiences. Here, each block is a snippet
									of my learning journey, crafted to inspire
									and inform. Dive into a mix of ideas and
									discoveries across tech topics. Join me in
									this venture of continuous learning and
									exploration, where every piece of content is
									an opportunity to grow together.
								</div>
							</div>
							<div className="projects-left-side">
								<div className="projects-image-container">
									<div className="projects-image-wrapper">
										<img
											src="blogs.svg" //https://storyset.com/illustration/blog-post/amico
											alt="about"
											className="projects-image"
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="blogs-list">
							<AllBlogs />
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Blogs;
