import "./ils.css";

const ILS = (props) => {
	return (
		<html>
			<head>
				<meta
					http-equiv="Content-Type"
					content="text/html; charset=utf-8"
				/>
				<title>🚪 Ingenious Lock System</title>
			</head>
			<body>
				<article
					id="24687a8f-309a-4519-add6-51ed4d29d39e"
					className="page sans"
				>
					<header>
						<div className="page-header-icon undefined">
							<span className="icon">🏠</span>
						</div>
						<h1 className="page-title">
							<strong>
								<strong>🚪 Ingenious Lock System</strong>
							</strong>
						</h1>
						<p className="page-description"></p>
					</header>
					<div className="page-body">
						<h3
							id="2547b75d-b869-43d4-a5a3-fe7513940f9e"
							className=""
						>
							<strong>Project Overview</strong>
						</h3>
						<p
							id="eb191ffc-9905-4d09-980c-362d82f98b17"
							className=""
						>
							The Ingenious Lock System is a smart lock designed
							to enhance home security through wireless control.
							It offers convenient and advanced features for door
							locking, integrating technology into the traditional
							home security approach.
						</p>
						<h3
							id="1514f21d-116d-494b-bfe4-c404ab9b5e82"
							className=""
						>
							<strong>📱 Features and Functionality</strong>
						</h3>
						<ul
							id="a5d4d0f2-1b02-4a5a-a813-58f4096dcc81"
							className="bulleted-list"
						>
							<li>
								<strong>Multilingual Voice Unlock</strong>: The
								app supports
								{" "}<mark className="highlight-teal_background">
									customizable voice commands
								</mark>{" "}
								in various languages for hands-free unlocking.
							</li>
						</ul>
						<ul
							id="753ced73-d053-410d-967d-47528206fa71"
							className="bulleted-list"
						>
							<li>
								<strong>Dynamic Keypad Security</strong>: The
								keypad adjusts codes for enhanced security and
								supports
								{" "}<mark className="highlight-teal_background">
									OTP for temporary users.
								</mark>{" "}
							</li>
						</ul>
						<ul
							id="05962579-a16b-4709-9a04-542b4b11a0bc"
							className="bulleted-list"
						>
							<li>
								<strong>Internet:</strong> allows to open the
								lock using the specific application designed for
								the purpose wherein the passcode will be preset.
							</li>
						</ul>
						<ul
							id="05b565c7-c35a-4b0b-b113-00f6e8675cdd"
							className="bulleted-list"
						>
							<li>
								<strong>One-Shot Facial Recognition</strong>:
								Added facial unlocking using a one-shot learning
								approach, requiring only a few images for
								initial setup.
							</li>
						</ul>
						<ul
							id="957c288d-bbfb-4c85-9f43-1997eefefb8b"
							className="bulleted-list"
						>
							<li>
								<strong>User-Friendly Registration</strong>:
								Designed for easy user enrollment with minimal
								effort and high accuracy.
							</li>
						</ul>
						<ul
							id="e20fe838-0afe-43e2-9c67-88f81e8da55a"
							className="bulleted-list"
						>
							<li>
								<strong>Remote Monitoring and Control</strong>:
								Allows the main user to
								{" "}<mark className="highlight-teal_background">
									control access and monitor home
								</mark>{" "}
								activities, including using the lock&#x27;s
								camera as a surveillance tool.
							</li>
						</ul>
						<blockquote
							id="8259d32a-3091-4349-9497-ba91ef040a4b"
							className="block-color-teal"
						>
							Our system combines practicality with advanced
							technology, offering diverse and secure home access
							options.
						</blockquote>
						<h3
							id="2d026890-d1e4-44b6-a0a2-56a3c994e703"
							className=""
						>
							<strong>
								📊 Data Collection and Model Training
							</strong>
						</h3>
						<ul
							id="1c3971b2-fe0b-42f8-a331-fd04fbc2d23f"
							className="bulleted-list"
						>
							<li>
								<strong>Voice and Facial Recognition</strong>:
								Gathered voice samples and facial images for
								training specific models like
								{" "}<mark className="highlight-teal_background">
									Convolutional Neural Networks (CNNs)
								</mark>{" "}
								for
								{" "}<mark className="highlight-teal_background">
									facial recognition and Recurrent Neural
									Networks (RNNs)
								</mark>{" "}
								for voice recognition.
							</li>
						</ul>
						<ul
							id="e49ce13a-4343-470e-8b12-cbc778e4e1b1"
							className="bulleted-list"
						>
							<li>
								<strong>Model Selection and Training</strong>:
								Utilized models like
								{" "}<mark className="highlight-teal_background">
									VGG-Face for facial unlocking
								</mark>{" "}
								and
								{" "}<mark className="highlight-teal_background">
									DeepSpeech for voice recognition
								</mark>{" "}
								. Employed a
								{" "}<mark className="highlight-teal_background">
									one-shot learning
								</mark>{" "}
								approach for efficient user facial recognition
								setup.
							</li>
						</ul>
						<blockquote
							id="bc1698e2-f4ea-4c4d-8d02-93f17dbe13c7"
							className="block-color-blue"
						>
							Our advanced training techniques ensure reliable and
							user-friendly security features.
						</blockquote>
						<h3
							id="2fb30c3d-f28a-45fa-bb6d-0130899fce4f"
							className=""
						>
							<strong>🔒 IoT Integration</strong>
						</h3>
						<ul
							id="412d61f4-82b5-48bc-8b14-2a4f4ef3cec8"
							className="bulleted-list"
						>
							<li>
								<strong>Hardware Setup</strong>: Integrated
								NodeMCU for Wi-Fi connectivity, along with
								camera, keypad, and Bluetooth modules.
							</li>
						</ul>
						<ul
							id="246a95fd-c034-475c-956a-5604dab3a192"
							className="bulleted-list"
						>
							<li>
								<strong>
									Application-Device Synchronization
								</strong>
								: Developed a comprehensive
								{" "}<mark className="highlight-teal_background">
									PWA web app
								</mark>{" "}{" "}
								for home management, enabling remote door
								unlocking and monitoring.
							</li>
						</ul>
						<blockquote
							id="548bc66d-80cc-4d61-a5bf-b82c5208e820"
							className="block-color-red"
						>
							The IoT framework forms the backbone of our system,
							connecting various components for seamless
							operation.
						</blockquote>
						<h3
							id="0104ee48-ebe2-41bb-978b-bba26fec3942"
							className=""
						>
							<strong>
								🌐 Future Scope for the Ingenious Lock System
							</strong>
						</h3>
						<ul
							id="7704030a-12ba-4e99-ba8b-24084097747b"
							className="bulleted-list"
						>
							<li>
								<strong>Advanced Biometric Integration</strong>:
								Exploring retina scans or fingerprint
								recognition for enhanced security.
							</li>
						</ul>
						<ul
							id="3b90455a-0377-4c01-8dbb-d7cef402d451"
							className="bulleted-list"
						>
							<li>
								<strong>
									AI-Powered Intruder Alert System
								</strong>
								: Implementing AI to recognize unusual behavior
								or unauthorized attempts at entry.
							</li>
						</ul>
						<ul
							id="3a0020bb-f83f-4ff6-a0af-459ed0923e25"
							className="bulleted-list"
						>
							<li>
								<strong>
									Integration with Smart Home Ecosystems
								</strong>
								: Expanding compatibility to seamlessly connect
								with various smart home devices.
							</li>
						</ul>
						<ul
							id="23e4dfb8-63d3-4946-b0d6-e9a1bcc7191a"
							className="bulleted-list"
						>
							<li>
								<strong>Cloud-Based Data Analysis</strong>:
								Utilizing cloud computing for real-time data
								analysis and predictive maintenance.
							</li>
						</ul>
						<ul
							id="fdd0edfb-fa64-4834-9a64-ec1a30d84238"
							className="bulleted-list"
						>
							<li>
								<strong>Global Market Expansion</strong>:
								Adapting the system for different market needs
								and regulations, aiming for international reach.
							</li>
						</ul>
						<blockquote
							id="8c6e3067-0d7c-4c02-84af-bee7a45e8ee0"
							className="block-color-brown"
						>
							These future developments are geared towards making
							the Ingenious Lock System a leader in smart home
							security technology.
						</blockquote>
						<p
							id="bb50e022-fba7-41c9-bcd2-dec2c68eb281"
							className=""
						></p>
					</div>
				</article>
				<span className="sans"></span>
			</body>
		</html>
	);
};

export default ILS;
