import "./aarogya.css";

const Aarogya = (props) => {
	return (<html>
        <head>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <title>🧠 Aarogya: AI-Powered Mental Health Solution</title>
 
        </head>
        <body>
          <article id="2c662b1f-372a-4068-98d2-583330a54dc3" className="page sans">
            <header>
              <div className="page-header-icon undefined">
                <span className="icon">👨🏻‍⚕️</span>
              </div>
              <h1 className="page-title">
                <strong>🧠 Aarogya: AI-Powered Mental Health Solution</strong>
              </h1>
              <p className="page-description"></p>
            </header>
            <div className="page-body">
              <h3 id="eb1f1da2-31b7-4e44-8f11-bda7ddbb4fba" className="">⛑️ Scope</h3>
              <p id="02e5ca98-cbd4-447f-9e63-3074748824cd" className="">
                The recent suicidal tragedy of one of our own UB students reinforced
                the importance of early. detection of depression and intervention for
                students&#x27; mental well-being.
              </p>
              <p id="9c677732-753e-4718-96e4-b236ce4ad2a8" className="">
                Aarogya is a privacy-preserving solution powered by the latest AI with
                advanced Natural Language Processing, Data Mining, Machine Learning
                coupled with hybrid cloud technologies to help address thiscritical
                societal issue.
              </p>
              <p id="8370b381-caba-4940-b8ab-ba91dc883cec" className="">
                The Aarogya platform combines multi-dimensional insights from sources
                such as people’s social media presence, psychological questionnaires,
                and therapist conversations to provide an effective detection and
                intervention solution for people with early signs of depression and
                suicidal thoughts.
              </p>
              <h3 id="949696b2-6eb7-4a36-a238-906c4cca39c2" className="">
                <strong><strong>🏆 Accomplishments and POC</strong></strong>
              </h3>
              <ul id="c218d8ba-b8cf-483d-b942-4c8eaeb43a3d" className="bulleted-list">
                <li>
                  Explored, benchmarked, and integrated multiple latest Neural
                  Networks NLP models for<br />Depression Detection such as BERT,
                  DistilBERT, and Logistic Regression<br />
                </li>
              </ul>
              <ul id="873bf99d-de9b-47f4-99c2-3705a52a2a01" className="bulleted-list">
                <li>
                  Integrated with Twitter API to obtain real-time social media data
                  for analysis
                </li>
              </ul>
              <ul id="7e35d817-a610-4fec-aac8-eff81442818e" className="bulleted-list">
                <li>
                  Automated the generation of Personal Health Questionnaires (PHQ8)
                  based on the Diagnostic<br />and Statistical Manual of Mental
                  Disorders (DSM-5)<br />
                </li>
              </ul>
              <ul id="49f98930-a6ed-400c-ae95-876a5dfc654f" className="bulleted-list">
                <li>
                  Analyzed data collected from Twitter, therapist dialogues, PHQ8, and
                  DSM-5 to evaluate the<br />severity of depression<br />
                </li>
              </ul>
              <ul id="50e7c2ae-a01b-4579-a281-ab27b38b0597" className="bulleted-list">
                <li>
                  Reached out and interviewed domain experts from UB Counselling
                  Services, Psychological<br />Association of Western New York (PAWNY)
                  to understand the needs and pain points<br />
                </li>
              </ul>
              <ul id="5fc6458f-615f-48de-bac7-c4e1af9ec895" className="bulleted-list">
                <li>
                  Prototyped the end-to-end AI Pipeline with React-based frontend
                  &amp; Cloud-based backend
                </li>
              </ul>
              <ul id="96fcc133-4c1c-42a8-a71a-7561697e4f78" className="bulleted-list">
                <li>
                  Tested on publicly available data sources like Distress Analysis
                  Interview Corpus (DAIC), Reddit<br />Self-reported Depression
                  Diagnosis (RSDD), etc<br />
                </li>
              </ul>
              <h3 id="794dc511-5b27-4072-9305-266f763a635f" className="">
                <strong>🚀 </strong>Novelty and Challenges Overcome
              </h3>
              <ul id="8cbf7b25-8f2a-4513-821c-9bd2d6f5821b" className="bulleted-list">
                <li>
                  The first data-driven solution combining Therapist Conversations,
                  Social Media Analysis, and<br />Psychological Questionnaires for
                  each early depression ideation and intervention<br />
                </li>
              </ul>
              <ul id="3c37a061-4d5e-431b-b44c-72cdef5026ac" className="bulleted-list">
                <li>
                  The first solution provides a continuous development of a
                  person&#x27;s mental well-being over<br />time based on rigorous
                  data analysis<br />
                </li>
              </ul>
              <ul id="44659c6b-316f-4936-b78b-b4af228975c1" className="bulleted-list">
                <li>
                  A systematic benchmarking of multiple alternative AI models for
                  practical adoption
                </li>
              </ul>
              <ul id="ce4200fc-76a5-4246-b5c1-66f04124810f" className="bulleted-list">
                <li>
                  A one-stop solution for friends and relatives concerned with a
                  changed behavior of the patient
                </li>
              </ul>
              <ul id="0435dace-9bf9-4a48-ae70-e3f70e31a6f5" className="bulleted-list">
                <li>
                  The first integrated solution with both detection of mental health
                  deterioration and a range of<br />recommended interventions <br />
                </li>
              </ul>
              <h3 id="706f2cba-b8e1-4bc9-9b89-24c08870aed3" className="">
                <strong><strong>💡 Technological and Societal Impact</strong></strong>
              </h3>
              <ul id="72346308-da90-4bc9-a63a-e7625ed50439" className="bulleted-list">
                <li>
                  Empowering both therapists and well-wishers of the patient to take
                  necessary precautions for<br />the patient<br />
                </li>
              </ul>
              <ul id="bcba8616-baf8-4162-ae3a-ce931f05ce8d" className="bulleted-list">
                <li>
                  Connecting people with designated stakeholders dedicated to helping
                  them
                </li>
              </ul>
              <ul id="439c00ee-fc6d-4092-8a23-53cf6c4c5084" className="bulleted-list">
                <li>
                  A Technical + Psychological Aid towards Suicide Detection and
                  Prevention
                </li>
              </ul>
              <ul id="423c208a-45cf-4bc2-b832-482a762d8d96" className="bulleted-list">
                <li>
                  Timeline-based behavioral analysis of Social Media Data
                </li>
              </ul>
              <h3 id="415ba32c-b543-456e-b5ae-0d1f0b7c11bd" className="">
                <strong><strong>🌟 Future Plans</strong></strong>
              </h3>
              <ul id="c6573dd1-09c3-4060-afb8-a7786108551a" className="bulleted-list">
                <li>
                  Improving the NLP models in terms of accuracy and usability
                </li>
              </ul>
              <ul id="577e1828-83a4-4a4d-b770-8bf0ab2e52c4" className="bulleted-list">
                <li>
                  Incorporating Confidential Computing for privacy preservation
                </li>
              </ul>
              <ul id="e7d91322-dae9-46b3-bc9d-b04f2d826cdc" className="bulleted-list">
                <li>
                  Preliminary deployment of the solution with the UB Counselling
                  Services portal
                </li>
              </ul>
              <ul id="067c13c6-0c1c-44f0-aebc-acd01ebb7a62" className="bulleted-list">
                <li>
                  Development of a mobile application for easier access
                </li>
              </ul>
              <ul id="1f7e925d-cb2d-4462-a2c8-b86734c51cee" className="bulleted-list">
                <li>
                  Solution to be open-source, free to use by any individual or
                  organization
                </li>
              </ul>
              <ul id="bba9be8a-1f16-445e-8c5e-079bec40e30f" className="bulleted-list">
                <li>
                  Deployment of the tool at scale after further updates and
                  improvements
                </li>
              </ul>
              <p id="1189ae81-1545-4cf5-a97f-5cc96250f811" className=""></p>
            </div>
          </article>
          <span className="sans"></span>
        </body>
      </html>
      );
};

export default Aarogya;