import "./uscia.css";

const USCIA = (props) => {
	return (
		<html>
			<head>
				<meta
					http-equiv="Content-Type"
					content="text/html; charset=utf-8"
				/>
				<title>
					📈 Unified System for Competitive Intelligence using AI
				</title>
			</head>
			<body>
				<article
					id="76024c27-408b-4a6c-b08d-56af78f6ab66"
					className="page sans"
				>
					<header>
						<div className="page-header-icon undefined">
							<span className="icon">🤖</span>
						</div>
						<h1 className="page-title">
							<strong>
								📈 Unified System for Competitive Intelligence
								using AI
							</strong>
						</h1>
						<p className="page-description"></p>
					</header>
					<div className="page-body">
						<h3
							id="af9dfb14-f82f-422b-9f2f-9864e6263768"
							className=""
						>
							🏢 Scope
						</h3>
						<p
							id="4c236d97-982c-4d8c-9a62-b42b528ef4a3"
							className=""
						>
							<strong>Problem</strong>: Companies spends around
							$600,000 annually on third-party tools to gather
							competitive intelligence, facing challenges like
							unorganized data archives and difficulty in
							extracting actionable insights.
						</p>
						<p
							id="ac0f9590-6793-4628-8c5b-2f8a4d26cc37"
							className=""
						>
							<strong>Solution</strong>: Develop an in-house
							AI-based solution, USCIA, to efficiently analyze and
							communicate competitor performance. This tool will
							include:
						</p>
						<ul
							id="83b6cb90-8f78-43e1-ad13-3bb64caa6f20"
							className="bulleted-list"
						>
							<li>
								<strong>Customized Dashboards</strong>: For easy
								access and analysis of competitor data.
							</li>
						</ul>
						<ul
							id="cd836217-73b9-47ea-8613-4eede3ada42c"
							className="bulleted-list"
						>
							<li>
								<strong>AI-Assisted Insights</strong>: Daily
								updates on competitors and market presence,
								aiding all employees in decision-making.
							</li>
						</ul>
						<ul
							id="0e94b734-354d-4dd4-a8b6-b7991193dc62"
							className="bulleted-list"
						>
							<li>
								<strong>Self-Serve Analytics</strong>: Enabling
								various departments like Corporate Strategy,
								Sales, Marketing, and Senior Leadership to gain
								crucial insights for strategic planning and
								market positioning.
							</li>
						</ul>
						<h3
							id="c42a245a-da78-404d-bdaf-5608c4a0861b"
							className=""
						>
							<strong>
								<strong>📊 Data Collection</strong>
							</strong>
						</h3>
						<ul
							id="4531f9c0-7963-4336-a5fb-830af8f62e33"
							className="bulleted-list"
						>
							<li>
								<strong>
									Daily News &amp; Product Releases (6 Years)
								</strong>
								: Aggregated news headlines, summaries, trending
								topics, polarity, and country frequency.
							</li>
						</ul>
						<ul
							id="9e7232da-6d57-4bd6-bf4c-c2d34d0c974a"
							className="bulleted-list"
						>
							<li>
								<strong>
									Quarterly Earnings Calls (4 Years)
								</strong>
								: Analyzed transcripts and AI-generated
								summaries.
							</li>
						</ul>
						<ul
							id="3090171a-5b31-4d75-b8d2-41db01caa505"
							className="bulleted-list"
						>
							<li>
								<strong>Financial Data (10 Years)</strong>:
								Collected data on revenue, EBITDA, profit &amp;
								loss, and a 3-year revenue forecast, along with
								acquisitions and investments.
							</li>
						</ul>
						<ul
							id="db04f711-0a9d-46c2-bb48-8d5095b6b5b0"
							className="bulleted-list"
						>
							<li>
								<strong>
									Social and Web Analytics (2 Years)
								</strong>
								: Trending Twitter and Reddit posts,
								impressions, engagements, website user stats,
								and top marketing channels.
							</li>
						</ul>
						<blockquote
							id="fb92d5f1-fa2c-4a9c-a80b-2e2ba0b23f4d"
							className="block-color-teal"
						>
							&quot;10 GB of open-source data spanning 10
							competitors across 200 countries, divided into four
							key sections for comprehensive competitive
							analysis.&quot;
						</blockquote>
						<h3
							id="04fbcf68-714d-445c-9b73-fbb0b95bdbd5"
							className=""
						>
							<strong>
								<strong>
									📊 EDA (Exploratory Data Analysis)
								</strong>
							</strong>
						</h3>
						<ul
							id="0213ab76-35f5-476e-9e27-d7e9cf81c4b8"
							className="bulleted-list"
						>
							<li>
								<strong>Data Profiling</strong>: Rigorously
								profiled various datasets to understand their
								characteristics and quality.
							</li>
						</ul>
						<ul
							id="8f932595-750b-446b-aa1b-d8bd0aff0bc0"
							className="bulleted-list"
						>
							<li>
								<strong>Trend Analysis</strong>: Analyzed
								long-term trends in news sentiment, financial
								performance, and social media engagement.
							</li>
						</ul>
						<ul
							id="7b0e544c-d7bf-49aa-a33a-572bbc0c1809"
							className="bulleted-list"
						>
							<li>
								<strong>Seasonality Checks</strong>:
								Investigated seasonal patterns in financial data
								and marketing activities.
							</li>
						</ul>
						<ul
							id="ebd75df1-b7b0-4993-b035-d19c44890d94"
							className="bulleted-list"
						>
							<li>
								<strong>Hypothesis Testing</strong>: Conducted
								statistical tests to validate assumptions about
								market trends and competitor strategies.
							</li>
						</ul>
						<ul
							id="bc86578c-36b3-47d5-bea1-32d71ab15e96"
							className="bulleted-list"
						>
							<li>
								<strong>Anomaly Detection</strong>: Identified
								unusual patterns or outliers in data that could
								indicate significant market shifts or
								competitive moves.
							</li>
						</ul>
						<blockquote
							id="cdd96f34-0603-4679-92fe-c4d78d88cd84"
							className="block-color-orange"
						>
							The EDA phase was crucial for extracting meaningful
							insights and patterns from complex datasets, forming
							the backbone of our predictive analytics.
						</blockquote>
						<h3
							id="9bc64e00-a09c-4a76-bd26-edfe4f25e63f"
							className=""
						>
							<strong>
								🤖 Model Development and Generative AI
							</strong>
						</h3>
						<ol
							type="1"
							id="f98d09d4-7f37-4937-8329-252d89282e54"
							className="numbered-list"
							start="1"
						>
							<li>
								<strong>
									Summarization and Sentiment Analysis
								</strong>
								: Implemented Generative AI to summarize news
								articles and quarterly earnings calls, providing
								condensed overviews and sentiment insights.
							</li>
						</ol>
						<ol
							type="1"
							id="679e3c1b-540e-4355-a25a-756e258b5290"
							className="numbered-list"
							start="2"
						>
							<li>
								<strong>
									Interactive AI Assistant Chatbot
								</strong>
								: Developed a sophisticated AI assistant that
								responds to user queries for in-depth insights
								and clarifications on competitors&#x27;
								performance.
							</li>
						</ol>
						<ol
							type="1"
							id="f4f21fed-277c-4628-a867-bba21b439948"
							className="numbered-list"
							start="3"
						>
							<li>
								<strong>Financial Data Analysis</strong>:
								Enabled financial analysts to access
								comprehensive fiscal data, investment patterns,
								and growth forecasts for strategic benchmarking.
							</li>
						</ol>
						<ol
							type="1"
							id="415c1e98-f728-4929-875f-48bef88f3715"
							className="numbered-list"
							start="4"
						>
							<li>
								<strong>Social Media Insights</strong>: Provided
								tools for sales and marketing teams to analyze
								social media trends, engagements, and public
								perceptions for informed marketing strategies.
							</li>
						</ol>
						<ol
							type="1"
							id="2f7f29d9-f9ca-4aba-a003-cf906e2a5e52"
							className="numbered-list"
							start="5"
						>
							<li>
								<strong>
									Competitor Website Traffic Analysis
								</strong>
								: Analyzed competitors&#x27; website traffic
								sources for benchmarking and customer
								acquisition strategy improvements.
							</li>
						</ol>
						<blockquote
							id="204af8bb-aab2-4a8e-b791-2f9a2b029154"
							className="block-color-blue"
						>
							The AI chatbot, a cornerstone of the system, offers
							tailored insights, facilitating deeper understanding
							of competitors’ strategies and market trends.
						</blockquote>
						<h3
							id="a13b8a6a-3db0-485b-b825-535299e4b976"
							className=""
						>
							<strong>🚀Future Scope and Deployment</strong>
						</h3>
						<p
							id="1802c25a-abbd-4b27-bcaa-f8196409eea1"
							className=""
						>
							<strong>Future Scope</strong>
						</p>
						<ul
							id="d4cc32fe-9361-4eb1-aa22-4dfaf07e02fd"
							className="bulleted-list"
						>
							<li>
								<strong>Enhanced Document Search</strong>:
								Improving the capability for document search and
								data retrieval.
							</li>
						</ul>
						<ul
							id="568bc26e-1c14-4c34-90fc-6f15951141f5"
							className="bulleted-list"
						>
							<li>
								<strong>Automated Data Analysis</strong>:
								Integrating automated data pull and AI analysis
								for real-time updates.
							</li>
						</ul>
						<ul
							id="0a2a5e53-ed24-4a5a-a781-37074be121b7"
							className="bulleted-list"
						>
							<li>
								<strong>
									AI-Based Strategy Recommendations
								</strong>
								: Adding features for AI-driven comparative
								analysis of competitors&#x27; initiatives.
							</li>
						</ul>
						<ul
							id="66aec5ac-b0e2-4b73-b1e0-304608acf8a6"
							className="bulleted-list"
						>
							<li>
								<strong>Chatbot Enhancement</strong>: Expanding
								the chatbot&#x27;s knowledge base and improving
								its response accuracy.
							</li>
						</ul>
						<p
							id="a7fd5016-b8a0-43a5-b088-de4f63370065"
							className=""
						>
							<strong>Deployment</strong>
						</p>
						<ul
							id="277cde75-e18b-4e5b-b595-3f35d0cb4524"
							className="bulleted-list"
						>
							<li>
								<strong>Frontend on Compute Engine</strong>:
								Deployed the user interface on Google
								Cloud&#x27;s Compute Engine for robust
								performance.
							</li>
						</ul>
						<ul
							id="37e9231c-8680-4ca0-b099-fe55d9b89691"
							className="bulleted-list"
						>
							<li>
								<strong>Backend on Cloud Run</strong>: Utilized
								Cloud Run for efficient backend operations.
							</li>
						</ul>
						<ul
							id="42a7c2ad-6ad9-4d34-b879-e274c634b103"
							className="bulleted-list"
						>
							<li>
								<strong>Chatbot and AI Models</strong>:
								Incorporated Palm API2 for Generative AI and
								chatbot functionalities.
							</li>
						</ul>
						<ul
							id="e5ecf05a-cbd1-4c33-a5cf-a2c34fd07d25"
							className="bulleted-list"
						>
							<li>
								<strong>Data Storage</strong>: Employed BigQuery
								(BQ) and Google Cloud Storage (GCS) for
								extensive data storage and management.
							</li>
						</ul>
						<blockquote
							id="82fc35c2-7366-4d69-8e54-88f991a58d73"
							className="block-color-red"
						>
							Future enhancements focus on automating intelligence
							gathering and analysis, with deployment leveraging
							Google Cloud&#x27;s scalable and efficient
							platforms.
						</blockquote>
					</div>
				</article>
				<span className="sans"></span>
			</body>
		</html>
	);
};

export default USCIA;
