const INFO = {
	main: {
		title: "Saj Maru",
		name: "Saj Maru",
		email: "sajmaru360@gmail.com",
		logo: "../logo.png",
	},

	education: {
		ub: "../ub.jpeg",
		mu: "../mu.png",
	},
	experience: {
		ups: "../up.png",
		tata_power: "../tata_power.png",
		imd: "../imd.webp",
		title: "👷🏻 Skills And Work Experience",
		description: `
		<strong> 🔨 Programming Languages: </strong>Python (Scikit-learn, Pandas, NumPy, SciPy, TensorFlow, NLTK, Keras, NLP), R, JavaScript, SQL, Bash
		<br/>
		<strong> 📑 Databases & Tools:</strong> MySQL, Big Query, MongoDB, Firebase, Neo4j, AWS, GCP, Jupyter Notebook, Docker, GIT, Tableau, Power BI, Hadoop
		<br/>
		<strong> 🚀 Data Science Technologies:</strong> Machine Learning, ETL, EDA, pipeline(cleaning, wrangling, visualization, modeling, interpretation), Statistics, Time Series, Regression, A/B Testing, APIs, Excel.`,
	},
	socials: {
		linkedin: "https://www.linkedin.com/in/sajmaru/",
		github: "https://github.com/sajmaru",
		medium: "https://medium.com/@sajmaru",
		instagram: "https://www.instagram.com/saj_maru/",
	},

	homepage: {
		title: "Data Scientist and Machine Learning Engineer",
		description:
			"👋 Hello there! I'm Saj Maru, a dedicated Data Scientist with a Master's Degree in Computer Science, armed with over 2 years of hands-on experience in the field. My expertise lies in the realm of predictive analytics, machine learning, and data mining. I thrive on the challenge of extracting valuable insights from raw data, and I'm well-versed in harnessing the power of Python,SQL and MLOps to accomplish this.",
		description1:
			"🚀 Every day, I'm excited to embark on new data-driven adventures, and I'm constantly on the lookout for opportunities to push my boundaries and make a positive impact in the world of data science.",
	},

	about: {
		title: "🧙🏻‍♂️ Data Whisperer: Crafting Insights from Chaos",
		description: `💻 I am a fast learner, detail-orientated, and result-driven team player who has effective communication skills to turn data into actionable insights, develop hypotheses, and drive decision-making. I am Data Scientist Co-op at UPS working on predictive analytics, building visualization dashboards, communicating with different stakeholders,s and turning business needs into technical solutions
			<br/>
			<br/>
			⏱️ Presently, I'm at the forefront of a transformative Gen AI project at UPS, leveraging the capabilities of Language Models (LLMs) and Vector Space databases. This endeavor involves:
			<ul>
			<li> Developing AI-driven <strong>chatbots</strong> that enhance customer interactions.</li> 
			<li> Elevating enterprise search systems for lightning-fast information retrieval.</li> 
			<li> Perfecting entity matching processes for precise data alignment.</li> 
			</ul>

			🚀  In this venture, I'm driven by the pursuit of measurable results, aiming to:
			<ul>
			<li>Improve response times for customer queries by ~30%.</li> 
			<li>Boost search accuracy, resulting in a 25%% increase in recall.</li> 
			</ul>`,
	},

	projects: {
		title: "👨🏻‍💻 Things I’ve made trying to put my dent in the universe.",
		description:
			"Welcome to my portfolio of projects – a diverse tapestry of challenges and innovations that I've navigated through over the years. Each project showcased here is a milestone in my journey, reflecting not just the skills and knowledge I've honed but also my passion for pushing boundaries and exploring new territories in technology. From intricate data analysis to pioneering AI solutions, these projects represent my relentless pursuit of excellence and impact.",
	},

	blogs: [
		{
			title: "Uncovering the Uncommon: Strategies for Anomaly Detection in Highly Skewed Datasets",
			description:
				"Addressing Class Imbalance with threshold moving and one-class classification",
			logo: "https://miro.medium.com/v2/resize:fit:1400/format:webp/0*v3Y2Ckvxf3fSdqwO.jpg",
			linkText: "Read Blog",
			link: "https://medium.com/@sajmaru/uncovering-the-uncommon-strategies-for-anomaly-detection-in-highly-skewed-datasets-d180391087e4",
		}
	],
};

export default INFO;
